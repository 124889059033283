import sortBy from 'lodash.sortby'
import { useSelector } from 'react-redux'
import { FilieresConfig } from '../../config'
import { selectEnabledFilieres } from '../../selectors/FiliereFilters'
import { selectFiliereFilters } from '../../selectors/PageMap'
import { RootState } from '../../store'
import { Filiere } from '../../types'

const FILIERES_SORT_ORDER: Array<Filiere> = [
    'Nucléaire',
    'Hydraulique',
    'Thermique non renouvelable',
    'Eolien',
    'Solaire',
    'Bioénergies',
    'Energies Marines',
    'Stockage non hydraulique',
    'Géothermie',
    'Autre',
]

export const useFiliereFilters = (isDataReadyToRender: boolean) => {
    const filieresShowing = useSelector(selectFiliereFilters)
    const filieresEnabled = useSelector((state: RootState) => {
        if (isDataReadyToRender) {
            return selectEnabledFilieres(state)
        }
        return []
    })
    const filieres = sortBy(
        Object.keys(FilieresConfig) as Array<Filiere>,
        (filiere) => FILIERES_SORT_ORDER.indexOf(filiere)
    )
    return { filieres, filieresShowing, filieresEnabled }
}
