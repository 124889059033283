import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import * as d3Hierarchy from 'd3-hierarchy'
import { D3HierarchyData, energyMixToD3Hierarchy } from '../../utils/treemap'
import { EnergyMix } from '../../types'
import GraphRect from './GraphRect'

export interface Props {
    width: number
    height: number
    energyMix: EnergyMix
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({
    width,
    height,
    energyMix,
    className = '',
}) => {
    const hierarchy = d3Hierarchy
        .hierarchy(energyMixToD3Hierarchy(energyMix))
        .sum((d) => (d as any).ratio)
        .sort((a, b) => b.value! - a.value!)
    const treeMap = d3Hierarchy
        .treemap()
        .size([width, height])
        // .tile(d3Hierarchy.treemapBinary)
        .padding(1)
        .round(true)

    const treeMapSquares = treeMap(hierarchy).children || []

    return (
        <svg
            viewBox={`0 0 ${width} ${height}`}
            width={width}
            height={height}
            className={className}
        >
            {treeMapSquares.map((rect) => {
                // We add width / height to the key to force a new rect (and a new text wrapping)
                const width = rect.x1 - rect.x0
                const height = rect.y1 - rect.y0
                return (
                    <GraphRect
                        key={`${
                            (rect.data as D3HierarchyData).filiere
                        }-${width}-${height}`}
                        {...rect}
                    />
                )
            })}
        </svg>
    )
}

export default styled(React.memo(Component))`
    display: block;
    /* Scale the graph to be sure it fit the width */
    width: 100%;
    height: auto;
    border-radius: ${theme.dimensions.borderRadius05};
`
