import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { Filiere } from '../../types'
import { ReactComponent as InactiveCrossSvg } from '../../images/energy-inactive.svg'
import { useDispatch } from 'react-redux'
import { toggleFiliereFilter } from '../../store/PageMap'
import FiliereDot from '../FiliereDot'
import { FilieresConfig } from '../../config'

const Label = styled.div<{ striked: boolean; disabled: boolean }>`
    color: ${theme.colors.Black};
    display: inline-block;
    margin-left: 0.3em;
    ${(props) =>
        props.striked && !props.disabled
            ? `
        text-decoration: line-through;
    `
            : ``}
    ${(props) =>
        props.disabled
            ? `
        color: ${theme.colors.Grey150};
    `
            : ``}
`

const CrossContainer = styled.div<{ color: string }>`
    display: inline-block;
    height: 1em;
    svg {
        display: block;
        height: 1em;
        * {
            fill: ${(props) => props.color};
        }
    }
`

const AlignContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export interface Props {
    filiere: Filiere
    isShowing: boolean
    disabled: boolean
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({
    filiere,
    isShowing,
    disabled,
    className = '',
}) => {
    const dispatch = useDispatch()
    const onClick = () => {
        if (!disabled) {
            dispatch(toggleFiliereFilter(filiere, !isShowing))
        }
    }
    const color = FilieresConfig[filiere].color
    return (
        <div className={className} onClick={onClick}>
            <AlignContainer>
                {disabled || isShowing ? (
                    <FiliereDot filiere={filiere} disabled={disabled} />
                ) : (
                    <CrossContainer color={color}>
                        <InactiveCrossSvg />
                    </CrossContainer>
                )}
                <Label disabled={disabled} striked={!isShowing}>
                    {FilieresConfig[filiere].display}
                </Label>
            </AlignContainer>
        </div>
    )
}

export default styled(React.memo(Component))`
    display: inline-block;
    vertical-align: middle;
    border-radius: 1em;
    border: 1px solid ${theme.colors.GreyBorder};
    padding: 0.15em ${theme.spacings.horizontal05};
    margin-right: ${theme.spacings.horizontal05};
    margin-bottom: ${theme.spacings.vertical05};
    user-select: none;
    ${(props) =>
        !props.disabled
            ? `
        cursor: pointer;
    `
            : ``}

    &:hover {
        ${(props) =>
            !props.disabled
                ? `
            border: 1px solid ${theme.colors.Black};
        `
                : ``}
    }
`
