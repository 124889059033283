import { all, takeLatest, put, select, call } from 'redux-saga/effects'
import { RAW_DATA_LOAD, rawDataLoadError, rawDataLoadSuccess } from './raw-data'
import {
    loadSeveralCollectionsAndErrors,
    MultipleResultsWithErrors,
} from '../core/db-core'
import configDb from '../config-db'
// eslint-disable-next-line
import IndexInstallationsWorker from 'worker-loader!./MapsContainer-worker'
import { SetTerritory, PAGE_MAP_SET_TERRITORY } from './PageMap'

import { Territory } from '../types'
import Flatbush from 'flatbush'
import { selectMapsData } from '../selectors/MapsContainer'
import {
    MapsData,
    IndexInstallationsWorkerRequest,
    GroupedFlatbushes,
    setInstallationFlatbushes,
} from './MapsContainer'
import { mapGroupedResult } from '../components/MapsContainer/utils'

const indexInstallationsWorker = new IndexInstallationsWorker()

const doIndexInstallations = (
    mapsData: MapsData,
    territory: Territory
): Promise<GroupedFlatbushes> => {
    return new Promise((resolve) => {
        indexInstallationsWorker.onmessage = (msg) => {
            const [groupsRaw, ...flatbushesRaw] = msg.data
            const groups = JSON.parse(groupsRaw)
            resolve(
                mapGroupedResult(mapsData, (_, group) => {
                    const i = groups.indexOf(group)
                    if (i === -1) {
                        console.error(
                            `Failed to rehydrate Flatbush for group ${group}`
                        )
                        // Returning dummy Flatbush instead
                        return new Flatbush(1)
                    }
                    return Flatbush.from(flatbushesRaw[i])
                })
            )
        }
        indexInstallationsWorker.postMessage(
            JSON.stringify({
                mapsData,
                territory,
            } as IndexInstallationsWorkerRequest)
        )
    })
}

function* indexInstallations(action: SetTerritory) {
    const mapsData = yield select(selectMapsData)
    const flatbushes: GroupedFlatbushes = yield call(
        doIndexInstallations,
        mapsData,
        action.payload
    )
    yield put(setInstallationFlatbushes(flatbushes))
}

function* getAllRawData() {
    const results: MultipleResultsWithErrors = yield loadSeveralCollectionsAndErrors(
        configDb,
        ['communes', 'departements', 'installationsMeta'],
        ['installations']
    )

    const loadedData: { [key: string]: any } = {}
    const errors: Array<[string, Error]> = []
    Object.entries(results).forEach(
        ([collectionId, [error, validatedData]]) => {
            if (validatedData) {
                loadedData[collectionId] = validatedData
            }
            if (error) {
                errors.push([collectionId, error])
            }
        }
    )

    if (errors.length === 0) {
        yield put(
            rawDataLoadSuccess({
                departements: loadedData.departements,
                installations: loadedData.installations,
                installationsMeta: loadedData.installationsMeta,
            })
        )
    } else {
        console.error(errors)
        yield put(rawDataLoadError(errors))
    }
}

function* rawDataLoadSaga() {
    yield takeLatest(RAW_DATA_LOAD, getAllRawData)
}

function* indexInstallationsSaga() {
    yield takeLatest(PAGE_MAP_SET_TERRITORY, indexInstallations)
}

export default function* rootSaga() {
    yield all([rawDataLoadSaga(), indexInstallationsSaga()])
}
