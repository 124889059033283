import { FilieresConfig } from './config'
import { enumValues } from './core/typescript'

export enum RequestStatus {
    INIT,
    PROGRESS,
    DONE,
}

export enum OUTREMER_DEPARTEMENTS {
    GUADELOUPE = '971',
    MARTINIQUE = '972',
    GUYANE = '973',
    LA_REUNION = '974',
}

export enum SPECIAL_DEPARTEMENTS {
    GUADELOUPE = '971',
    MARTINIQUE = '972',
    GUYANE = '973',
    LA_REUNION = '974',
    CORSE_DU_SUD = '2A',
    HAUTE_CORSE = '2B',
}

export const SPECIAL_DEPARTEMENTS_VALUES = enumValues(
    SPECIAL_DEPARTEMENTS
) as Array<string>

export type Filiere = keyof typeof FilieresConfig

export interface RouteParams {
    territoryType?: string
    territoryName?: string
}

export enum TerritoryType {
    PAYS = 'pays',
    REGION = 'region',
    DEPARTEMENT = 'departement',
}

export interface Territory {
    type: TerritoryType
    name: string
}

export const FRANCE_TERRITORY = { type: TerritoryType.PAYS, name: 'france' }

export interface TerritoryInsee {
    type: TerritoryType
    insee: string | null
}

export type InstallationId = number

export type FiliereFilters = Array<Filiere>

export type Position = [number, number]

export type Dimensions = { width: number; height: number }

export type EnergyMix = { [filiere: string]: number | null }

export type InstallationValueField = 'energie' | 'puissance'
