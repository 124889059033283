import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { selectWindowDimensions } from '../../selectors/core'
import {
    selectMapTerritory,
    selectPageMapInitialized,
} from '../../selectors/PageMap'
import {
    setTerritory,
    setVisualizationWidth,
    initializePageMapState,
} from '../../store/PageMap'
import { TerritoryType, RouteParams, FRANCE_TERRITORY } from '../../types'
import { isInEnumValues } from '../../core/typescript'

const getCurrentVisualizationWidth = (
    measureContainerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
    if (measureContainerRef.current) {
        const {
            width: measureContainerWidth,
        } = measureContainerRef.current.getBoundingClientRect()
        return Math.floor(measureContainerWidth)
    }
    return 0
}

const getCurrentTerritory = (territoryType: string, territoryName: string) =>
    isInEnumValues(TerritoryType, territoryType)
        ? { type: territoryType, name: territoryName }
        : FRANCE_TERRITORY

export const useSyncPageMapState = (
    measureContainerRef: React.MutableRefObject<HTMLDivElement | null>
) => {
    const dispatch = useDispatch()
    // 1. CURRENT redux state
    const territoryInState = useSelector(selectMapTerritory)
    const pageMapInitialized = useSelector(selectPageMapInitialized)
    // 2. ACTUAL app state
    const {
        territoryType = TerritoryType.PAYS,
        territoryName = FRANCE_TERRITORY.name,
    } = useParams<RouteParams>()
    const { width: windowWidth } = useSelector(selectWindowDimensions)

    // 3. Synchronize the redux state with actual app state
    useEffect(() => {
        if (pageMapInitialized) {
            dispatch(
                initializePageMapState(
                    getCurrentTerritory(territoryType, territoryName),
                    getCurrentVisualizationWidth(measureContainerRef)
                )
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!pageMapInitialized) {
            dispatch(
                setVisualizationWidth(
                    getCurrentVisualizationWidth(measureContainerRef)
                )
            )
        }
        // We re-calculate this only when window width has changed, because for some reason
        // `measureContainerRef.current.getBoundingClientRect()` returns 2 slightly different results
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowWidth, dispatch])

    useEffect(() => {
        if (!pageMapInitialized) {
            dispatch(
                setTerritory(getCurrentTerritory(territoryType, territoryName))
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [territoryType, territoryName, dispatch])

    return territoryInState !== null
}
