import { createSelector } from 'reselect'
import { Commune, Installation } from '../config-db'
import {
    CanvasDot,
    MouseOverState as MouseOverStateMapsContainer,
} from '../store/MapsContainer'
import { MouseOverSectionInfoState } from '../store/PageMap'
import { MouseOverState as MouseOverStateTreeMap } from '../store/TreeMap'
import { FiliereFilters, InstallationValueField, Position } from '../types'
import {
    selectInstallationsById,
    selectInstallationsByCommune,
    InstallationsByCommune,
    InstallationsById,
} from './core'
import { selectMouseOver as selectMouseOverMapsContainer } from './MapsContainer'
import {
    selectFiliereFilters,
    selectInstallationValueField,
    selectMouseOverSectionInfo,
} from './PageMap'
import { selectMouseOver as selectMouseOverTreeMap } from './TreeMap'

export interface InfoBoxMapData {
    installations: Array<Installation>
    commune: Commune
    highlightedCanvasDot: CanvasDot
    pageMousePosition: Position
}

export interface InfoBoxSectionInfoData {
    text: string
    pageMousePosition: Position
}

export type InfoBoxTreeMapData = MouseOverStateTreeMap

export const selectInfoBoxMapData = createSelector(
    selectMouseOverMapsContainer,
    selectInstallationsById,
    selectInstallationsByCommune,
    selectFiliereFilters,
    selectInstallationValueField,
    (
        mouseOver: MouseOverStateMapsContainer,
        installationsById: InstallationsById,
        installationsByCommune: InstallationsByCommune,
        filiereFilters: FiliereFilters,
        installationValueField: InstallationValueField
    ): InfoBoxMapData | null => {
        if (!mouseOver) {
            return null
        }
        const { canvasDots, pageMousePosition } = mouseOver
        const installations = canvasDots
            .map((canvasDot) => installationsById[canvasDot.installationId])
            .filter((installation) =>
                filiereFilters.includes(installation.filiere)
            )

        const biggestInstallationWithIndex = installations.reduce(
            (biggestInstallationWithIndex, installation, i) => {
                const installationWithIndex: [Installation, number] = [
                    installation,
                    i,
                ]
                if (!filiereFilters.includes(installation.filiere)) {
                    return biggestInstallationWithIndex
                }
                if (biggestInstallationWithIndex) {
                    const [biggestInstallation] = biggestInstallationWithIndex
                    return installation[installationValueField] >
                        biggestInstallation[installationValueField]
                        ? installationWithIndex
                        : biggestInstallationWithIndex
                }
                return installationWithIndex
            },
            null as null | [Installation, number]
        )

        if (biggestInstallationWithIndex) {
            const [
                biggestInstallation,
                biggestInstallationIndex,
            ] = biggestInstallationWithIndex
            const highlightedCanvasDot = canvasDots[biggestInstallationIndex]
            return {
                installations: installationsByCommune[
                    biggestInstallation.commune.insee_com
                ].filter((installation) =>
                    filiereFilters.includes(installation.filiere)
                ),
                commune: biggestInstallation.commune,
                highlightedCanvasDot,
                pageMousePosition: [
                    pageMousePosition[0],
                    pageMousePosition[1] + highlightedCanvasDot.radius,
                ],
            }
        }
        return null
    }
)

export const selectInfoBoxMapKey = createSelector(
    selectInfoBoxMapData,
    (data: InfoBoxMapData | null) =>
        data !== null ? data.commune.insee_com : null
)

export const selectInfoBoxTreeMapData = createSelector(
    selectMouseOverTreeMap,
    (mouseOver: MouseOverStateTreeMap): InfoBoxTreeMapData => {
        if (!mouseOver || !mouseOver.textHidden) {
            return null
        }

        return mouseOver
    }
)

export const selectInfoBoxSectionInfo = createSelector(
    selectMouseOverSectionInfo,
    (mouseOver: MouseOverSectionInfoState): InfoBoxSectionInfoData | null => {
        if (!mouseOver) {
            return null
        }
        return {
            text: mouseOver.text,
            pageMousePosition: mouseOver.pageMousePosition,
        }
    }
)
