import { createSelector } from 'reselect'
import { Departement } from '../config-db'
import { selectDepartements } from './core'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import {
    FRANCE_TERRITORY,
    OUTREMER_DEPARTEMENTS,
    Territory,
    TerritoryType,
} from '../types'
import { territoryToUrl } from '../utils/territories'
import { isInEnumValues } from '../core/typescript'

const OUTREMER_FAKE_REGION = 'Outre-mer'

interface RegionFilter {
    nom: string
    departements: Array<Departement>
}

export interface SelectOption {
    value: {
        url: string
        territory: Territory
        disabled?: boolean
    }
    label: string
}

export const selectSortedTerritories = createSelector(
    selectDepartements,
    (departements: Array<Departement>): Array<RegionFilter> => {
        const outremers: RegionFilter = {
            nom: OUTREMER_FAKE_REGION,
            departements: sortBy(
                departements.filter((departement) =>
                    isInEnumValues(OUTREMER_DEPARTEMENTS, departement.insee_dep)
                ),
                (departement) => departement.nom_dep
            ),
        }

        let regionNomAndDepartements = Object.entries(
            groupBy(
                departements.filter(
                    (departement) =>
                        !isInEnumValues(
                            OUTREMER_DEPARTEMENTS,
                            departement.insee_dep
                        )
                ),
                (departement) => departement.nom_reg
            )
        )
        regionNomAndDepartements = sortBy(
            regionNomAndDepartements,
            ([regionNom]) => regionNom
        )
        return [
            ...regionNomAndDepartements.map(([, departements]) => {
                departements = sortBy(
                    departements,
                    (departement) => departement.nom_dep
                )
                return {
                    nom: departements[0].nom_reg,
                    departements,
                }
            }),
            outremers,
        ]
    }
)

export const selectTerritoryOptions = createSelector(
    selectSortedTerritories,
    (regionFilters: Array<RegionFilter>): Array<SelectOption> => {
        return regionFilters.reduce(
            (allOptions, region) => {
                return [
                    ...allOptions,
                    {
                        label: region.nom,
                        value: {
                            url: territoryToUrl(
                                TerritoryType.REGION,
                                region.nom
                            ),
                            territory: {
                                type: TerritoryType.REGION,
                                name: region.nom,
                            },
                            disabled: region.nom === OUTREMER_FAKE_REGION,
                        },
                    },
                    ...region.departements.map((departement) => ({
                        label: departement.nom_dep,
                        value: {
                            url: territoryToUrl(
                                TerritoryType.DEPARTEMENT,
                                departement.nom_dep
                            ),
                            territory: {
                                type: TerritoryType.DEPARTEMENT,
                                name: departement.nom_dep,
                            },
                        },
                    })),
                ]
            },
            [
                {
                    label: 'France entière',
                    value: {
                        url: territoryToUrl(TerritoryType.PAYS, ''),
                        territory: FRANCE_TERRITORY,
                    },
                },
            ] as Array<SelectOption>
        )
    }
)
