import { Comma, Quote } from 'csv-string/dist/types'
import theme from './theme'
import { TerritoryType } from './types'

const ENV = process.env.REACT_APP_ENV

export default {
    // ---------- Base config
    ENV,
    PDF_EXPORT_TOKEN:
        ENV === 'prod'
            ? 'U2FsdGVkX1+SYwU4E/IdSFmwk2lV9NlRTHBN2DfOL+2p2jWob9jAoCl0SEocEkHfJvPloTWd3k6IR/gJhv9JuDC8OQjhcRjNmbJcDeRrT64='
            : 'U2FsdGVkX19bknqmjCgbAPkkobgy+c1doeXigNXcFaB6Htha7HSlLglGyMUL/2HZgnalEsm64ap+UHd/wMF+t2bo/gbhqJILGG/5RqZojUxFNZJUjKXM0Bd2Bxc2lCYFYBlTPv08ye2h/Izrz6IP32OGnc94UVFmE+rEwsSTpBI=',
    PDF_EXPORT_URL:
        ENV === 'prod'
            ? 'https://api.lestrafiquants.tools/v1/pdf/agence-ore/html-to-pdf'
            : 'https://staging-api.lestrafiquants.tools/v1/pdf/agence-ore/html-to-pdf',
    SITE_URL: 'https://dataviz.agenceore.fr/production-elec-par-territoire',
    GOOGLE_WEB_FONTS: ['Open Sans'],
    ROOT_URL:
        process.env.ORE_ENV === 'staging'
            ? ''
            : '/production-elec-par-territoire',
    CSV_QUOTE: '"' as Quote,
    CSV_DELIMITER: ',' as Comma,
    // ---------- Map config
    VISUALIZATION_PIXEL_SIZE: 1000,
    INFOBOX_DEBOUNCE_TIME: 20,
    MAP_CORSE_OFFSET: [0.6, -0.3] as [number, number],
    MAP_CORSE_LINE_FRANCE: [
        [6.532209, 42.575661],
        [8.396859, 43.825865],
    ] as [[number, number], [number, number]],
    // +/- pixels added to the pointer position for detecting
    // an installation in the flatbush index
    MAP_OVER_ZONE_PIXELS: 5,
    // Min / max size of the radius of canvas dots, given as a
    // proportion of the map size.
    PUISSANCE_MAPPING_WIDTH_RATIO: {
        [TerritoryType.PAYS]: [0.001, 0.03],
        [TerritoryType.REGION]: [0.002, 0.04],
        [TerritoryType.DEPARTEMENT]: [0.002, 0.05],
    },
    // Threshold in ratio of [min_value, max_value], for deciding
    // whether a dot should be on the big dots canvas.
    BIG_INSTALLATION_VALUE_THRESHOLD: 0.3,
    // ---------- Misc
    PUISSANCE_MULT_WATTS: 1000,
    NUMBERS_ROUND_DECIMAL: 2,
    TWITTER_TEXT:
        'Découvrez la carte des sites de production électrique en France | @agenceORE avec les données #ODRE @reseauxenergies',
}

// mapLayerZIndex forces some types of energies to display on top of others
export const FilieresConfig = {
    'Thermique non renouvelable': {
        color: theme.colors.ThermiqueNonRenouvelable,
        colorFilterMobile: undefined,
        colorText: theme.colors.White,
        mapLayerZIndex: 8,
        display: 'Thermique',
    },
    Solaire: {
        color: theme.colors.Solaire,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Solaire',
    },
    Bioénergies: {
        color: theme.colors.Bioenergies,
        colorFilterMobile: undefined,
        colorText: theme.colors.White,
        mapLayerZIndex: 1,
        display: 'Bioénergies',
    },
    Eolien: {
        color: theme.colors.Eolien,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Éolien',
    },
    Nucléaire: {
        color: theme.colors.Nucleaire,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 10,
        display: 'Nucléaire',
    },
    Hydraulique: {
        color: theme.colors.Hydraulique,
        colorFilterMobile: undefined,
        colorText: theme.colors.White,
        mapLayerZIndex: 6,
        display: 'Hydraulique',
    },
    'Energies Marines': {
        color: theme.colors.EnergiesMarines,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Énergies marines',
    },
    Géothermie: {
        color: theme.colors.Géothermie,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Géothermie',
    },
    Autre: {
        color: theme.colors.Black,
        colorFilterMobile: theme.colors.White,
        colorText: theme.colors.White,
        mapLayerZIndex: 1,
        display: 'Autres',
    },
    'Stockage non hydraulique': {
        color: theme.colors.StockageNonHydraulique,
        colorFilterMobile: undefined,
        colorText: theme.colors.Black,
        mapLayerZIndex: 1,
        display: 'Stockage non hydraulique',
    },
}
