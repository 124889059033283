import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import Select, { ValueType, components } from 'react-select'
import { ReactComponent as EnergySvg } from '../../images/icon-energy.svg'
import Menu from '../FooterMobile/Menu'
import { useFiliereFilters } from './hooks'
import ReactSelectOption, { OptionData } from './ReactSelectOption'
import { useDispatch } from 'react-redux'
import { toggleFiliereFilter } from '../../store/PageMap'
import { FilieresConfig } from '../../config'

const StyledPlaceholder = styled(components.SingleValue)`
    display: flex;
    flex-direction: row;
    > span {
        color: ${theme.colors.Black};
        font-weight: bold;
        font-size: 110%;
        margin-right: 0.35em;
    }
`

const makeSingleValueComponent = (isDataReadyToRender: boolean) => (
    props: any
) => {
    const { filieresShowing } = useFiliereFilters(isDataReadyToRender)
    return (
        <StyledPlaceholder {...props}>
            <span>
                {filieresShowing.length < Object.keys(FilieresConfig).length
                    ? filieresShowing.length
                    : ''}
            </span>
            <EnergySvg />
        </StyledPlaceholder>
    )
}

export interface Props {
    isDataReadyToRender: boolean
    menuPortalTarget: HTMLDivElement | null
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const FiliereFiltersMobile: React.FunctionComponent<Props> = ({
    menuPortalTarget,
    isDataReadyToRender,
    className = '',
}) => {
    const dispatch = useDispatch()
    const { filieres, filieresShowing, filieresEnabled } = useFiliereFilters(
        isDataReadyToRender
    )
    const onChange = (_option: ValueType<OptionData>) => {
        const option = _option as OptionData
        if (option && !option.disabled) {
            dispatch(toggleFiliereFilter(option.filiere, !option.isShowing))
        }
    }
    const options = filieres.map((filiere) => ({
        filiere,
        isShowing: filieresShowing.includes(filiere),
        disabled: !filieresEnabled.includes(filiere),
        isSwitch: false,
    }))

    options.unshift({
        filiere: 'Autre',
        isShowing: true,
        disabled: false,
        isSwitch: true,
    })

    return (
        <Select
            className={className}
            classNamePrefix="ReactSelect"
            options={options}
            // Dummy selected value just to force the select
            // to render our SingleValue component
            value={options[0]}
            menuPlacement="top"
            isSearchable={false}
            onChange={onChange}
            components={{
                Menu,
                SingleValue: makeSingleValueComponent(isDataReadyToRender),
                Option: ReactSelectOption,
            }}
            menuPortalTarget={menuPortalTarget}
            // Without this setting, the closeMenuOnSelect=false has no effect.
            // REF : https://github.com/JedWatson/react-select/issues/2771
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
        />
    )
}

export default styled(React.memo(FiliereFiltersMobile))`
    ${theme.mixins.resetReactSelectMixin}
    ${theme.mixins.reactSelectMobileMixin}
    .ReactSelect__control {
        height: ${theme.dimensions.footerMobileSelectHeight}px;
    }
`
