import React from 'react'
import styled from 'styled-components/macro'
import { CanvasDot } from '../../store/MapsContainer'

const Circle = styled.circle<{ color: string }>`
    fill: ${(props) => props.color};
    stroke-width: 0px;
    filter: url(#dropshadow);
`

export interface Props {
    width: number
    height: number
    canvasDot: CanvasDot | null
    className?: string
}

const HighlightInstallations: React.FunctionComponent<Props> = ({
    width,
    height,
    canvasDot,
    className = '',
}) => {
    let canvasDotElement: JSX.Element | null = null
    if (canvasDot) {
        canvasDotElement = (
            <>
                <filter
                    id="dropshadow"
                    x={-1}
                    y={-1}
                    width={canvasDot.radius * 2 + 3}
                    height={canvasDot.radius * 2 + 3}
                >
                    <feDropShadow dx="0" dy="0" stdDeviation="3" />
                </filter>
                <Circle
                    color={canvasDot.color}
                    r={canvasDot.radius}
                    cx={canvasDot.position[0]}
                    cy={canvasDot.position[1]}
                />
            </>
        )
    }
    /* REF : https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3 */
    return (
        <svg className={className} viewBox={`0 0 ${width} ${height}`}>
            {canvasDotElement}
        </svg>
    )
}

export default styled(React.memo(HighlightInstallations))`
    pointer-events: none;
    width: 100%;
    height: auto;
`
