import { Departement } from '../config-db'
import { Territory, TerritoryType } from '../types'

export const territoriesAreEqual = (
    territory1: Territory,
    territory2: Territory
) => territory1.type === territory2.type && territory1.name === territory2.name

export const getRegionInseeFromName = (
    departements: Array<Departement>,
    regionName: string
) => {
    for (let departement of departements) {
        if (departement.nom_reg === regionName) {
            return departement.insee_reg
        }
    }
    console.error(`region ${regionName} not found`)
    return null
}

export const getDepartementInseeFromName = (
    departements: Array<Departement>,
    departementName: string
) => {
    for (let departement of departements) {
        if (departement.nom_dep === departementName) {
            return departement.insee_dep
        }
    }
    console.error(`region ${departementName} not found`)
    return null
}

export const territoryToUrl = (type: TerritoryType, name: string) =>
    type === TerritoryType.PAYS ? `/` : `/${type}/${name}`
