import React from 'react'
import styled from 'styled-components/macro'
import { Installation } from '../../config-db'
import theme from '../../theme'
import { FilieresConfig } from '../../config'
import { formatValue } from '../../utils/format'
import FiliereDot from '../FiliereDot'
import { Filiere } from '../../types'
import { useSelector } from 'react-redux'
import { selectInstallationValueField } from '../../selectors/PageMap'

const NomInstallation = styled.div`
    color: ${theme.colors.Black};
    text-transform: uppercase;
    font-weight: bold;
`

const FiliereContainer = styled.div``

const FiliereLabel = styled.span`
    color: ${theme.colors.Black};
`

const InstallationCount = styled.div`
    color: ${theme.colors.Black};
    font-weight: bold;
`

const InstallationValue = styled.div`
    color: ${theme.colors.Black};
    font-weight: bold;
    font-size: 115%;
`

export interface Props {
    installation: Installation
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const InfoBoxMapEntry: React.FunctionComponent<Props> = ({
    installation,
    className = '',
}) => {
    const installationValueField = useSelector(selectInstallationValueField)
    const { filiere, nominstallation, nbinstallations } = installation
    const unit = installationValueField === 'puissance' ? 'W' : 'Wh'
    const formattedValue = formatValue(
        installation[installationValueField],
        unit
    )
    return (
        <div className={className}>
            {/* Installations are aggregated in preprocessing. When an installation represents an aggregate, its name will be '' */}
            {nominstallation.length ? (
                <NomInstallation>{nominstallation}</NomInstallation>
            ) : null}
            <FiliereContainer>
                <FiliereDot filiere={filiere} disabled={false} />
                <FiliereLabel>
                    {FilieresConfig[filiere as Filiere].display}
                </FiliereLabel>
            </FiliereContainer>
            {nbinstallations > 1 ? (
                <InstallationCount>
                    {nbinstallations}{' '}
                    {filiere === 'Nucléaire' ? 'tranches' : 'installations'}
                </InstallationCount>
            ) : null}
            <InstallationValue
                dangerouslySetInnerHTML={{ __html: formattedValue }}
            ></InstallationValue>
        </div>
    )
}

export default styled(React.memo(InfoBoxMapEntry))`
    ${FiliereDot} {
        margin-right: 0.3em;
        position: relative;
        top: 0.1em;
    }
`
