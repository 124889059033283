import wrapFetch from 'fetch-retry'
import { readAll as csvRead } from 'csv-string'
import { Comma, Quote } from 'csv-string/dist/types'

const fetchWithRetry = wrapFetch(fetch)

const RETRY_SETTINGS = {
    retries: 3,
    retryDelay: 1000,
}

export const fetchJSON = (url: string) =>
    fetchWithRetry(url, RETRY_SETTINGS).then((response) => response.json())

export const fetchCSV = (url: string, csvDelimiter: Comma, csvQuote: Quote) =>
    fetchWithRetry(url, RETRY_SETTINGS)
        .then((response) => response.text())
        .then(
            (text) =>
                new Promise((resolve) => {
                    csvRead(text, csvDelimiter, csvQuote, resolve)
                })
        )
