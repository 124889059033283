import React from 'react'
import { useSelector } from 'react-redux'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import {
    selectInfoBoxMapData,
    selectInfoBoxMapKey,
    selectInfoBoxSectionInfo,
    selectInfoBoxTreeMapData,
} from '../../selectors/InfoBoxes'
import theme from '../../theme'
import { Position } from '../../types'
import InfoBoxContainer from './InfoBoxContainer'
import InfoBoxMap from './InfoBoxMap'
import InfoBoxSectionInfo from './InfoBoxSectionInfo'
import InfoBoxTreeMap from './InfoBoxTreeMap'

const InfoBoxes: React.FunctionComponent = () => {
    const infoBoxMapData = useSelector(selectInfoBoxMapData)
    const infoBoxTreeMapData = useSelector(selectInfoBoxTreeMapData)
    const infoBoxSectionInfoData = useSelector(selectInfoBoxSectionInfo)
    const infoBoxMapKey = useSelector(selectInfoBoxMapKey)
    let infoBoxPosition: Position | null = null
    let infoBoxContent: JSX.Element | null
    let infoBoxKey = ''

    if (infoBoxMapData) {
        infoBoxContent = <InfoBoxMap infoBoxData={infoBoxMapData} />
        infoBoxKey = infoBoxMapKey!
        infoBoxPosition = infoBoxMapData.pageMousePosition
    } else if (infoBoxTreeMapData) {
        infoBoxContent = <InfoBoxTreeMap infoBoxData={infoBoxTreeMapData} />
        infoBoxKey = infoBoxTreeMapData!.filiere
        infoBoxPosition = infoBoxTreeMapData.pageMousePosition
    } else if (infoBoxSectionInfoData) {
        infoBoxContent = (
            <InfoBoxSectionInfo infoBoxData={infoBoxSectionInfoData!} />
        )
        infoBoxKey = infoBoxSectionInfoData.text
        infoBoxPosition = infoBoxSectionInfoData.pageMousePosition
    } else {
        return null
    }

    return (
        <SwitchTransition>
            <CSSTransition
                key={infoBoxKey}
                appear
                timeout={{
                    enter: theme.transitions.infoboxEnterDuration,
                    exit: theme.transitions.infoboxExitDuration,
                }}
                classNames="InfoBox"
            >
                <InfoBoxContainer position={infoBoxPosition}>
                    {infoBoxContent}
                </InfoBoxContainer>
            </CSSTransition>
        </SwitchTransition>
    )
}

export default InfoBoxes
