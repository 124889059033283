import React from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import Select from 'react-select'
import { useTerritorySelect } from './hooks'
import Menu from '../FooterMobile/Menu'
import ReactSelectOption from './ReactSelectOption'

const StyledMenu = styled(Menu)`
    .ReactSelect__menu-list {
        .ReactSelect__option {
            &.ReactSelect__option--pays {
                text-transform: uppercase;
                border-bottom: 2px solid ${theme.colors.White};
            }
            &.ReactSelect__option--region {
                text-transform: uppercase;
            }
        }
    }
`

export interface Props {
    menuPortalTarget: HTMLDivElement | null
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const TerritoryFiltersMobile: React.FunctionComponent<Props> = ({
    menuPortalTarget,
    className = '',
}) => {
    const { onChange, options, selectedOption } = useTerritorySelect()
    return (
        <Select
            className={className}
            classNamePrefix="ReactSelect"
            value={selectedOption}
            options={options}
            menuPlacement="top"
            isSearchable={false}
            onChange={onChange}
            components={{ Menu: StyledMenu, Option: ReactSelectOption }}
            menuPortalTarget={menuPortalTarget}
        />
    )
}

export default styled(React.memo(TerritoryFiltersMobile))`
    ${theme.mixins.resetReactSelectMixin}
    ${theme.mixins.reactSelectMobileMixin}
    .ReactSelect__control {
        height: ${theme.dimensions.footerMobileSelectHeight}px;
    }
`
