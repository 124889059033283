import mergeImages from 'merge-images'
import config, { FilieresConfig } from '../config'
import logoSrc from '../images/logo-ORE-color-@2x.png'
import theme from '../theme'
import { Filiere } from '../types'
import { formatNumber, zeroPrePadNumber } from './format'

const downloadFile = (file: Blob, contentType: string, title: string) => {
    file = file.slice(0, file.size, contentType)
    const fileURL = URL.createObjectURL(file)
    const a = document.createElement('a')
    a.href = fileURL
    a.target = '_blank'
    a.download = title
    document.body.appendChild(a)
    a.click()
}

const generatePdf = async (title: string, htmlStr: string) => {
    const response = await fetch(config.PDF_EXPORT_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + config.PDF_EXPORT_TOKEN,
        },
        body: JSON.stringify({
            datas: htmlStr,
            title: title,
            source: document.location.origin, // NECESSAIRE POUR aller chercher les assets css & images sur le serveur distant
            emulateMedia: 'print',
            // ...optionsPrint,
        }),
    })

    return response.blob()
}

export const exportPdf = async (title: string, htmlStr: string) =>
    generatePdf(title, htmlStr).then((file) => {
        downloadFile(file, 'application/pdf', 'Graphique')
    })

export const exportWindow = async (title: string, htmlStr: string) => {
    const newWindow = window.open()!
    newWindow.document.write(htmlStr)
    newWindow.document.title = title
}

export const getSvgStr = (element: HTMLElement) => {
    const clonedNode = element
        .querySelector('svg')
        ?.cloneNode(true) as SVGElement
    clonedNode.removeAttribute('width')
    clonedNode.removeAttribute('height')
    return clonedNode!.outerHTML
}

export const mergeCanvases = (element: HTMLElement) => {
    const canvases = element.querySelectorAll('canvas')
    const layerSrcs: Array<string> = []

    canvases.forEach((canvas) => {
        layerSrcs.push(canvas.toDataURL('image/png'))
    })
    return mergeImages(layerSrcs)
}

export const generateFilieresLegendHtml = (
    filiereFilters: Array<{ filiere: Filiere; value?: string }>,
    subtotal?: number
) => {
    const hasSubtotal = subtotal !== undefined
    let subtotalStr = ''
    if (hasSubtotal) {
        subtotalStr =
            subtotal! < 0.1 ? '< 0.1%' : `${formatNumber(subtotal!, 1)}%`
    }
    return `
        <div class="filieresLegend">
            <div class="title">
                Filières${hasSubtotal ? ' affichées' : ''}
                ${
                    hasSubtotal
                        ? `<div class="subtitle">représentant ${subtotalStr} du parc installé</div>`
                        : ''
                }
            </div>
            ${filiereFilters
                .map(
                    ({ filiere, value }) => `
                    <div class="filiere">
                        <div class="filiereDot" style="background-color: ${
                            FilieresConfig[filiere].color
                        };"></div> 
                        <span>${FilieresConfig[filiere].display}</span>
                        ${value ? `<span class="value">${value}</span>` : ''}
                    </div>
                `
                )
                .join('')}
        </div>
    `
}

export const generateHtml = (
    title: string,
    subtitle: string,
    vizContent: string,
    className?: string
) => {
    const date = new Date()
    return `
        <html>
            <head>
                <link
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap"
                    rel="stylesheet"
                />
                <style>
                    body {
                        font-family: ${theme.fonts.default};
                        color: ${theme.colors.DarkBlue};
                    }
                    .mainContainer {
                        display: flex;
                        flex-direction: column;
                        height: 100%;
                        width: 100%;
                        justify-content: space-between;
                    }
                    .header {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-weight: bold;
                        font-size: 25px;
                        line-height: 110%;                    
                        border-bottom: 20px solid ${theme.colors.Green};
                        margin: 0.5em 1.5em;
                        margin-bottom: 0;
                    }
                    .header img {
                        width: 20%;
                    }
                    .title {
                        text-transform: uppercase;
                        margin-bottom: 1em;
                    }
                    .title .subtitle {
                        font-size: 90%;
                        text-transform: initial;
                    }

                    .vizContainer {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                    .vizContainer > * {
                        width: 70%;
                        height: auto;
                    }
                    .vizContainer .map img {
                        width: 100%;
                        border: 2px solid ${theme.colors.Grey};
                        border-radius: 0.3em;
                    }

                    .vizContainer .treeMap svg {
                        width: 100%;
                    }
                    
                    .filieresLegend {
                        line-height: 200%;
                    }
                    .filieresLegend .title {
                        margin-bottom: 0;
                    }
                    .filieresLegend .title .subtitle {
                        line-height: 110%;
                        margin-bottom: 0.5em;
                    }
                    .filieresLegend .filiere {
                        display: inline-block;
                        margin-right: 1em;
                    }
                    .filieresLegend .filiere .value {
                        font-weight: bold;
                    }
                    .filieresLegend .filiereDot {
                        display: inline-block;
                        width: 1em;
                        height: 1em;
                        border-radius: 1em;
                    }

                    .map .legend {
                        display: flex;
                        flex-direction: row;
                        margin-top: 1em;
                    }
                    .treeMap .legend {
                        margin-top: 0.8em;
                    }
                    .treeMapViz.vizContainer > * {
                        width: 60%;
                        height: auto;
                    }
                    .vizContainer .treeMap svg text {
                        font-size: 180%;
                    }

                    .legendSizeInstallations {
                        padding: 2em;
                        border: 2px solid ${theme.colors.Grey};
                        border-radius: 0.3em;
                        margin-right: 2em;
                    }
                    .legendSizeInstallationsItem {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }
                    .legendSizeInstallationsItem > div:first-child {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-right: 0.5em;
                    }
                    .legendSizeInstallationsItem > div:first-child > div {
                        border: 1px solid black;
                        box-sizing: border-box;
                    }

                    .footer {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        padding: 3.5em 3em;
                        padding-top: 0;
                        font-size: 70%;
                    }
                </style>
            </head>
            <body>
                <div class="mainContainer">
                    <div class="header">
                        <img src="${logoSrc}" />
                        <span>Répartition des installations de production et de stockage électrique</span>
                    </div>
                    <div class="vizContainer ${className || ''}">
                        <div class="title">
                            <div>
                                ${title}
                            </div>
                            <div class="subtitle">
                                ${subtitle}
                            </div>
                        </div>
                        ${vizContent}
                    </div>
                    <div class="footer">
                        <div>${config.SITE_URL}</div>
                        <div>Export du ${zeroPrePadNumber(
                            date.getDate()
                        )}/${zeroPrePadNumber(
        date.getMonth() + 1
    )}/${zeroPrePadNumber(date.getFullYear())}</div>
                    </div>
                </div>
            </body>
        </html>
    `
}
