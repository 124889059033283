import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Select from 'react-select'
import SectionTitle from '../SectionTitle'
import theme from '../../theme'
import { ReactComponent as SearchArrowSvg } from '../../images/search-arrow.svg'
import { ReactComponent as SearchDeleteSvg } from '../../images/search-delete.svg'
import { ReactComponent as SearchGlassSvg } from '../../images/search-glass.svg'
import { buildTransitionHelper } from '../../core/animations'
import { CSSTransition } from 'react-transition-group'
import { useTerritorySelect } from './hooks'
import ReactSelectOption from './ReactSelectOption'

const CSS_TRANSITION_CLASS_NAME = 'TerritoryFiltersDesktop'

const borderMixin = `1px solid ${theme.colors.Black}`

const StyledSelect = styled(Select)`
    ${theme.mixins.resetReactSelectMixin}

    .ReactSelect__control {
        border: ${borderMixin};
        background: rgb(252, 251, 248);
        background: linear-gradient(
            180deg,
            rgba(252, 251, 248, 1) 60%,
            rgba(239, 237, 223, 1) 100%
        );
        &:hover {
            border: ${borderMixin};
        }
        &.ReactSelect__control--is-focused {
            border: ${borderMixin};
        }
        .ReactSelect__indicators {
            padding-right: 0.7em;
            svg {
                display: block;
            }
        }
    }

    .ReactSelect__menu {
        z-index: ${theme.zIndexes.selectMenu};
        border: ${borderMixin};
        border-radius: ${theme.dimensions.borderRadius05};

        .ReactSelect__menu-list {
            margin: 0.5em 0;
        }

        .ReactSelect__option {
            cursor: pointer;
            &:nth-child(2n) {
                background-color: ${theme.colors.Grey50};
                &.ReactSelect__option--is-focused {
                    background-color: ${theme.colors.GreyPer40};
                }
            }
            &:nth-child(2n + 1) {
                background-color: ${theme.colors.White};
                &.ReactSelect__option--is-focused {
                    background-color: ${theme.colors.Grey50Per50};
                }
            }
            &.ReactSelect__option--region {
                text-transform: uppercase;
            }
            &.ReactSelect__option--departement {
                padding-left: 2em;
            }
        }
    }
`

const Instructions = styled.div`
    color: ${theme.colors.Grey150};
    font-size: 82%;
    margin-top: 0.3em;
    margin-left: 1em;
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const TerritoryFiltersDesktop: React.FunctionComponent<Props> = ({
    className = '',
}) => {
    const {
        onChange,
        options,
        selectedOption,
        filterOption,
    } = useTerritorySelect()
    const [inputValue, setInputValue] = useState('')
    const [isFocused, setIsFocused] = useState(false)

    const onFocus = () => {
        setIsFocused(true)
    }
    const onBlur = () => {
        setIsFocused(false)
    }
    const onInputChange = (newInputValue: string) => {
        setInputValue(newInputValue)
    }

    const IndicatorsContainer = () => {
        let svgComponent: JSX.Element | null = null
        if (inputValue && inputValue.length) {
            svgComponent = (
                <SearchDeleteSvg
                    className="ReactSelect__indicator-delete"
                    onClick={() => setInputValue('')}
                />
            )
        } else if (isFocused) {
            svgComponent = (
                <SearchGlassSvg className="ReactSelect__indicator-search" />
            )
        } else {
            svgComponent = (
                <SearchArrowSvg className="ReactSelect__indicator-arrow" />
            )
        }
        return <div className="ReactSelect__indicators">{svgComponent}</div>
    }

    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                <SectionTitle>Zone Géographique</SectionTitle>
                <StyledSelect
                    placeholder="Chercher..."
                    value={selectedOption}
                    noOptionsMessage={() => 'Aucun résultat'}
                    classNamePrefix="ReactSelect"
                    isClearable
                    options={options}
                    controlShouldRenderValue={!isFocused}
                    onChange={onChange}
                    onInputChange={onInputChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    components={{
                        IndicatorsContainer,
                        Option: ReactSelectOption,
                    }}
                    filterOption={filterOption}
                />
                <Instructions>
                    Sélectionnez une région ou un département
                </Instructions>
            </div>
        </CSSTransition>
    )
}

const {
    title,
    select,
} = theme.transitions.pageEnterSequence.TerritoryFiltersDesktop
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(TerritoryFiltersDesktop))`
    .${CSS_TRANSITION_CLASS_NAME}-active ${StyledSelect} {
        /* So content doesn't show during transition */
        overflow: hidden;
    }

    ${appearTransition(
        [`${SectionTitle}`, `${Instructions}`],
        [['opacity', '0', '1']],
        title[0],
        title[1]
    )}

    ${appearTransition(
        [`${StyledSelect}`],
        [
            ['width', '0%', '100%'],
            ['opacity', '0', '1'],
        ],
        select[0],
        select[1]
    )}
`
