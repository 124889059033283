import React, { PropsWithChildren } from 'react'
import styled from 'styled-components'
import theme from '../../theme'
import { ReactComponent as CloseSvg } from '../../images/search-delete.svg'
import { Link } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import config from '../../config'

export const CSS_TRANSITION_CLASSNAMES = 'MapTitle'

type VariantType = 'black' | 'grey'

interface Props {
    className?: string
    hasCloseButton?: boolean
    in: boolean
    variant?: VariantType
}

const MapTitle: React.FunctionComponent<PropsWithChildren<Props>> = ({
    className,
    in: in_,
    children,
    hasCloseButton = false,
}) => {
    return (
        <CSSTransition
            in={in_}
            appear
            classNames={CSS_TRANSITION_CLASSNAMES}
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                {hasCloseButton ? (
                    <Link to={config.ROOT_URL + '/'}>
                        <CloseSvg className="close" />
                    </Link>
                ) : null}
                {children}
            </div>
        </CSSTransition>
    )
}

export default styled(React.memo(MapTitle))`
    position: absolute;
    top: ${theme.spacings.vertical05};
    left: ${theme.spacings.horizontal05};
    z-index: ${theme.zIndexes.mapControls};
    /* to prevent selection of text on touch */
    user-select: none;
    ${(props) =>
        props.variant === 'grey'
            ? `color: ${theme.colors.Grey150};`
            : `color: ${theme.colors.Black};`}

    a {
        display: inline-block;
        vertical-align: middle;
        margin-right: 0.3em;
        svg {
            height: 1em;
        }
    }

    svg.close:hover {
        * {
            fill: ${theme.colors.Green};
            stroke: ${theme.colors.Green};
        }
    }
`
