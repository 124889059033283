import * as yup from 'yup'
import { DataRaw, DataInflated, DataValidated } from './db-core'

export const fetchInMemory = async (data: DataRaw) => Promise.resolve(data)

const rowToObject = <T>(fieldNames: Array<string>, row: Array<any>) => {
    const datum: { [fieldName: string]: any } = {}
    fieldNames.forEach(
        (fieldName, columnIndex) => (datum[fieldName] = row[columnIndex])
    )
    return datum as T
}

export const inflateRowsWithHeaderAsArray = async <
    T extends { [fieldName: string]: any }
>(
    dataRaw: Array<Array<string>>
): Promise<Array<T>> => {
    const fieldNames = dataRaw[0]
    return Promise.resolve(
        dataRaw.slice(1).map((row) => rowToObject<T>(fieldNames, row))
    )
}

export const inflateRowsWithHeaderAsObject = async <
    T extends { [fieldName: string]: any }
>(
    idFieldName: keyof T,
    dataRaw: Array<Array<string>>
): Promise<{ [id: string]: T }> => {
    const fieldNames = dataRaw[0]
    const objectsById: { [id: string]: T } = {}
    dataRaw.slice(1).forEach((row) => {
        const datum = rowToObject<T>(fieldNames, row)
        objectsById[datum[idFieldName]] = datum as T
    })
    return Promise.resolve(objectsById)
}

export const validateRowYup = (
    validationSchema: yup.Schema<any>,
    dataInflated: DataInflated
): Promise<DataValidated> => {
    return yup
        .array()
        .of(validationSchema)
        .validate(dataInflated, { abortEarly: false }) as Promise<DataValidated>
}

export const validateYup = (
    validationSchema: yup.Schema<any>,
    dataInflated: DataInflated
): Promise<DataValidated> => {
    return validationSchema.validate(dataInflated, {
        abortEarly: false,
    }) as Promise<DataValidated>
}

export const identity = <T>(a: T) => Promise.resolve(a)
