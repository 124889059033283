import React, { useState } from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'
import { ReactComponent as FbSvg } from '../images/icon-fb.svg'
import { ReactComponent as LknSvg } from '../images/icon-lkn.svg'
import { ReactComponent as TwtSvg } from '../images/icon-twt.svg'
// import odreSrc from '../images/logo-ODRE-@2x.png'
// import oreSrc from '../images/logo-ORE-color-@2x.png'
import APropos from './Modals/APropos'
import MentionsLegales from './Modals/MentionsLegales'
import config from '../config'
import {
    buildFacebookShareUrl,
    buildLinkedInShareUrl,
    buildTwitterShareUrl,
} from '../core/social-networks'
import { useSelector } from 'react-redux'
import { selectDataDate } from '../selectors/core'
import { zeroPrePadNumber } from '../utils/format'

type MODAL_TYPE = 'A_PROPOS' | 'MENTIONS_LEGALES' | null

const SIZE_SHARE_BUTTONS = '3em'

const Section = styled.div`
    border-top: 1px solid ${theme.colors.Grey};
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: ${theme.spacings.vertical1};
    &:first-child {
        border: none;
    }
`

const SectionSocialMedia = styled(Section)`
    align-items: center;
    > * {
        margin-right: ${theme.spacings.horizontal05};
    }
`

const SocialMediaContainer = styled.a`
    background-color: ${theme.colors.Black};
    border-radius: ${SIZE_SHARE_BUTTONS};
    width: ${SIZE_SHARE_BUTTONS};
    height: ${SIZE_SHARE_BUTTONS};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: ${theme.colors.Green};
    }
`

const ModalButton = styled.li`
    &:hover {
        color: ${theme.colors.Green};
    }
`

// const SectionLogosOre = styled(Section)`
//     padding: 0;
//     a {
//         display: inline-block;
//         &:first-child {
//             width: 60%;
//         }
//         &:last-child {
//             width: 40%;
//         }
//         img {
//             width: 100%;
//         }
//     }
// `

const DataDate = styled.div`
    font-size: 75%;
`

const Nav = styled.nav`
    width: 100%;
    ul {
        width: 100%;
        list-style: none;
        display: flex;
        flex-direction: row;
        justify-content: center;
        li {
            cursor: pointer;
            padding: 0 ${theme.spacings.horizontal1};
            &:first-child {
                border-right: solid 1px ${theme.colors.Black};
                text-align: right;
            }
        }
    }
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const FooterInfos: React.FunctionComponent<Props> = ({ className = '' }) => {
    const dataDate = useSelector(selectDataDate)
    const [openModal, setOpenModal] = useState<MODAL_TYPE>(null)
    const aProposClick = () => setOpenModal('A_PROPOS')
    const mentionsLegalesClick = () => setOpenModal('MENTIONS_LEGALES')
    let modal: JSX.Element | null = null
    const onCloseModal = () => setOpenModal(null)
    if (openModal === 'A_PROPOS') {
        modal = <APropos onClose={onCloseModal} />
    } else if (openModal === 'MENTIONS_LEGALES') {
        modal = <MentionsLegales onClose={onCloseModal} />
    }

    return (
        <div className={className}>
            {modal}
            <Section>
                <DataDate>
                    Données à jour du {zeroPrePadNumber(dataDate.getUTCDate())}/
                    {zeroPrePadNumber(dataDate.getUTCMonth() + 1)}/
                    {dataDate.getUTCFullYear()}
                </DataDate>
            </Section>
            <Section>
                <Nav>
                    <ul>
                        <ModalButton onClick={aProposClick}>
                            Méthodologie
                        </ModalButton>
                        <ModalButton onClick={mentionsLegalesClick}>
                            Mentions légales
                        </ModalButton>
                    </ul>
                </Nav>
            </Section>
            <SectionSocialMedia>
                <span>Partagez</span>
                <SocialMediaContainer
                    target="blank_"
                    href={buildFacebookShareUrl(config.SITE_URL)}
                >
                    <FbSvg />
                </SocialMediaContainer>
                <SocialMediaContainer
                    target="blank_"
                    href={buildLinkedInShareUrl(config.SITE_URL)}
                >
                    <LknSvg />
                </SocialMediaContainer>
                <SocialMediaContainer
                    target="blank_"
                    href={buildTwitterShareUrl(
                        config.SITE_URL,
                        config.TWITTER_TEXT
                    )}
                >
                    <TwtSvg />
                </SocialMediaContainer>
            </SectionSocialMedia>
            {/* <SectionLogosOre>
                <a
                    href="https://opendata.reseaux-energies.fr/pages/accueil/"
                    target="blank_"
                >
                    <img src={odreSrc} alt="logo ODRE" />
                </a>
                <a href="https://www.agenceore.fr/" target="blank_">
                    <img src={oreSrc} alt="logo ORE" />
                </a>
            </SectionLogosOre> */}
        </div>
    )
}

export default styled(React.memo(FooterInfos))`
    max-width: 320px;
    margin: auto;
`
