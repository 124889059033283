import React from 'react'
import { components, OptionProps } from 'react-select'
import styled from 'styled-components/macro'
import { SelectOption } from '../../selectors/TerritoryFilters'

const ReactSelectOption: React.FunctionComponent<OptionProps<SelectOption>> = ({
    data,
    className,
    ...props
}) => {
    return (
        <components.Option
            className={`ReactSelect__option--${data.value.territory.type} ${
                className || ''
            } ${data.value.disabled ? `ReactSelect__option--disabled` : ``}`}
            {...(props as any)}
        >
            {props.children}
        </components.Option>
    )
}

export default styled(ReactSelectOption)`
    &.ReactSelect__option {
        font-size: 117%;
        &.ReactSelect__option--disabled {
            pointer-events: none;
        }
    }
`
