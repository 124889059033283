export const enumValues = <T>(enumType: T) =>
    Object.keys(enumType).map((k) => enumType[k as keyof typeof enumType])

export const isInEnumValues = <T>(
    enumType: T,
    candidate: any
): candidate is T[keyof T] => Object.values(enumType).includes(candidate)

export const guard = <T>(value: T | undefined | null): T => {
    if (value === undefined || value === null) {
        throw new Error(`Expected value to be neither null nor undefined`)
    }
    return value
}

// Generic type guard typescript.
// REF : https://dev.to/krumpet/generic-type-guard-in-typescript-258l
interface typeMap {
    // for mapping from strings to types
    string: string
    number: number
    boolean: boolean
}

type PrimitiveOrConstructor = { new (...args: any[]): any } | keyof typeMap // 'string' | 'number' | 'boolean' | constructor

// infer the guarded type from a specific case of PrimitiveOrConstructor
type GuardedType<T extends PrimitiveOrConstructor> = T extends {
    new (...args: any[]): infer U
}
    ? U
    : T extends keyof typeMap
    ? typeMap[T]
    : never

// finally, guard ALL the types!
export const typeGuard = <T extends PrimitiveOrConstructor>(
    value: any,
    className: T
): value is GuardedType<T> => {
    const localPrimitiveOrConstructor: PrimitiveOrConstructor = className
    if (typeof localPrimitiveOrConstructor === 'string') {
        return typeof value === localPrimitiveOrConstructor
    }
    return value instanceof localPrimitiveOrConstructor
}

export const cast = <T extends PrimitiveOrConstructor>(
    value: any,
    className: T
) => {
    if (!typeGuard(value, className)) {
        throw new Error(
            `Value ${value} is not of the expected type ${className}`
        )
    }
    return value
}
