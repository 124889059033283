import config from '../config'
import round from 'lodash.round'

const NO_PREFIX: [number, string] = [1, '']
const KILO: [number, string] = [Math.pow(10, 3), 'k']
const MEGA: [number, string] = [Math.pow(10, 6), 'M']
const GIGA: [number, string] = [Math.pow(10, 9), 'G']
const MEASURES: Array<[number, string]> = [NO_PREFIX, KILO, MEGA, GIGA]

export const formatNumber = (
    num: number,
    decimal: number = config.NUMBERS_ROUND_DECIMAL
) => round(num, decimal).toString(10).replace('.', ',')

export const formatValue = (
    value: number,
    unit: string,
    decimal: number = config.NUMBERS_ROUND_DECIMAL
) => {
    const adjustedValue = value * config.PUISSANCE_MULT_WATTS
    let measureIndex = 0
    while (
        measureIndex < MEASURES.length - 1 &&
        adjustedValue / MEASURES[measureIndex + 1][0] > 1
    ) {
        measureIndex++
    }
    const [divisor, prefix] = MEASURES[measureIndex]
    return `${formatNumber(
        adjustedValue / divisor,
        decimal
    )}&nbsp;${prefix}${unit}`
}

export const zeroPrePadNumber = (value: number, charCount: number = 2) => {
    let valueStr = value.toString(10)
    while (valueStr.length < charCount) {
        valueStr = '0' + valueStr
    }
    return valueStr
}

export const capitalize = (s: string) =>
    s.slice(0, 1).toUpperCase() + s.slice(1)
