import React from 'react'
import styled from 'styled-components/macro'
import Modal from './Modal'

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
    onClose: () => void
}

const Component: React.FunctionComponent<Props> = ({
    onClose,
    className = '',
}) => {
    return (
        <Modal title="Méthodologie" className={className} onClose={onClose}>
            <p>
                L’objectif de cette datavisualisation est de représenter
                géographiquement les différentes installations de production et
                de stockage électrique sur le territoire français, en affichant
                l’énergie produite ou la puissance installée. Le mix en énergie
                ou la répartition en puissance par filière sont aussi illustrés.
                <br />
                <br />
                Les mailles territoriales disponibles sont le territoire
                métropolitain et les DOM, les régions et les départements.
                <br />
                <br />
                Les données sont issues du registre national des installations
                de production d’électricité et de stockage mis à disposition{' '}
                <a
                    href="https://opendata.reseaux-energies.fr/pages/accueil/"
                    target="blank_"
                >
                    sur la plateforme ODRE
                </a>
                .
                <br />
                <br />
                Les différents sites de production et de stockage électrique
                sont géolocalisés par leur commune d’emplacement.
            </p>

            <h3>Hypothèses de construction</h3>
            <ul>
                <li>
                    <b>
                        La puissance retenue pour les sites est en priorité la
                        puissance maximale installée
                    </b>
                    . Si cette valeur est indisponible, la puissance de
                    raccordement en production sur le réseau est retenue. Enfin
                    si cette valeur est également indisponible, la puissance de
                    raccordement en soutirage est utilisée (ce dernier cas ne
                    survient que pour quelques installations de stockage non
                    hydraulique).
                </li>
                <li>
                    <b>
                        L’énergie produite correspond à celle sur les 12
                        derniers mois. Si cette valeur était absente, une
                        estimation est réalisée à partir de la puissance
                        installée en utilisant des coefficients
                    </b>
                    . Ces coefficients ont été dimensionnés en faisant le ratio
                    entre les puissances installées et énergies produites de
                    chaque filière dans le Bilan Electrique 2020. Les
                    coefficients sont :
                    <br />
                    <table>
                        <tbody>
                            <tr>
                                <th>FILIERE</th>
                                <th>COEFFICIENT </th>
                            </tr>
                            <tr>
                                <td>SOLAIRE</td>
                                <td>1213 </td>
                            </tr>
                            <tr>
                                <td>THERMIQUE</td>
                                <td>1986 </td>
                            </tr>
                            <tr>
                                <td>HYDRAULIQUE</td>
                                <td>2530 </td>
                            </tr>
                            <tr>
                                <td>EOLIEN</td>
                                <td>2254 </td>
                            </tr>
                            <tr>
                                <td>BIOENERGIES</td>
                                <td>4422</td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    Les filières nucléaire, géothermie, stockage non hydraulique
                    et autres n’ont pas été reconstituées.
                </li>

                <li>
                    Afin d’optimiser l’ergonomie et les temps de chargement de
                    la datavisualisation, les installations photovoltaïques ne
                    sont pas toutes représentées visuellement sur la vue globale
                    France.
                </li>
            </ul>
        </Modal>
    )
}

export default styled(React.memo(Component))`
    user-select: text;

    ul {
        padding-left: 1em;
    }
    li {
        margin-bottom: 1em;
    }
    table {
        border: 1px solid black;
        border-collapse: collapse;
        margin: 1em 0;
    }
    tr {
        border-bottom: 1px solid black;
        &:last-child {
            border-bottom: none;
        }
    }
    th,
    td {
        padding: 0.2em;
        border-right: 1px solid black;
        &:last-child {
            border-right: none;
        }
    }
`
