import React, { useEffect, useRef } from 'react'
import styled from 'styled-components/macro'
import theme from '../../theme'
import TerritoryFiltersMobile from '../TerritoryFilters/TerritoryFiltersMobile'
import FiliereFiltersMobile from '../FiliereFilters/FiliereFiltersMobile'
import { useForceUpdate } from '../../core/animations/hooks'
import { buildTransitionHelper } from '../../core/animations'
import { CSSTransition } from 'react-transition-group'

const CSS_TRANSITION_CLASSNAMES = 'FooterMobile'

const TerritorySelect = styled(TerritoryFiltersMobile)`
    flex: 1;
    margin-right: ${theme.spacings.horizontal05};
`
const FilieresSelect = styled(FiliereFiltersMobile)`
    min-width: 7em;
`

const MenuPortalContainer = styled.div`
    /* to make z-index above other elements (e.g. map) work */
    position: relative;
    z-index: ${theme.zIndexes.selectMenu};
`

export interface Props {
    isDataReadyToRender: boolean
    pageScrollIsAtBottom: boolean
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({
    isDataReadyToRender,
    className = '',
}) => {
    const menuPortalTargetRef = useRef<HTMLDivElement | null>(null)
    /* for update to take into account the filled-in ref */
    const forceUpdate = useForceUpdate()
    useEffect(
        () => forceUpdate(),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    )
    return (
        <>
            <CSSTransition
                classNames={CSS_TRANSITION_CLASSNAMES}
                in={true}
                timeout={theme.transitions.enterDuration}
                appear
            >
                <div className={className}>
                    <TerritorySelect
                        menuPortalTarget={menuPortalTargetRef.current}
                    />
                    <FilieresSelect
                        menuPortalTarget={menuPortalTargetRef.current}
                        isDataReadyToRender={isDataReadyToRender}
                    />
                </div>
            </CSSTransition>
            <MenuPortalContainer
                ref={menuPortalTargetRef}
            ></MenuPortalContainer>
        </>
    )
}

const { all } = theme.transitions.pageEnterSequenceMobile.Header
const enterTransition = buildTransitionHelper.enterOrAppear(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(Component))`
    height: ${theme.dimensions.footerMobileHeight}px;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: ${theme.zIndexes.footerHeader};
    padding: 0 ${theme.spacings.horizontal05};
    ${theme.mixins.mobileGradient}
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${(props) =>
        !props.pageScrollIsAtBottom
            ? `
        box-shadow: 0px -1px 14px 0px rgba(0,0,0,0.4);
    `
            : ``}

    ${enterTransition(
        null,
        [['transform', 'translateY(100%)', 'translateY(0%)']],
        all[0],
        all[1]
    )}
`
