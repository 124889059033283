// Sharing buttons, no JavaScript see https://sharingbuttons.io/

export const buildLinkedInShareUrl = (siteUrl: string) => {
    const encodedUrl = encodeURIComponent(siteUrl)
    return `https://www.linkedin.com/sharing/share-offsite/?url=${encodedUrl}`
}

export const buildTwitterShareUrl = (
    siteUrl: string,
    tweetShareText: string
) => {
    const encodedUrl = encodeURIComponent(siteUrl)
    const encodedShareTweet = encodeURIComponent(tweetShareText)
    return `https://twitter.com/intent/tweet/?text=${encodedShareTweet}&url=${encodedUrl}`
}

export const buildFacebookShareUrl = (siteUrl: string) => {
    const encodedUrl = encodeURIComponent(siteUrl)
    return `https://facebook.com/sharer/sharer.php?u=${encodedUrl}`
}
