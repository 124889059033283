type TransitionPropertyName = string
type TransitionPropertyValue = string

const cssTransition = (
    properties: Array<TransitionPropertyName>,
    start: number,
    duration: number,
    transitionFunction: string = 'ease'
) => {
    return `transition: ${properties
        .map((prop) => `${prop} ${duration}ms ${transitionFunction} ${start}ms`)
        .join(', ')};`
}

const _buildTransitionHelper = (
    classBase: string,
    initStates: Array<string>,
    transStates: Array<string>,
    transitionDuration: number
) => (
    selectors: Array<string> | null,
    definitions: Array<
        [
            TransitionPropertyName,
            TransitionPropertyValue,
            TransitionPropertyValue
        ]
    >,
    startRatio: number,
    durationRatio: number
) => {
    const durationTime = durationRatio * transitionDuration
    const startTime = startRatio * transitionDuration
    const shared = cssTransition(
        definitions.map((prop) => prop[0]),
        startTime,
        durationTime
    )

    const generateClasses = (states: Array<string>) =>
        states
            .map((state) =>
                (selectors || [''])
                    .map((selector) => `${classBase}-${state} ${selector}`)
                    .join(', ')
            )
            .join(', ')

    const initClasses = generateClasses(initStates)
    const transClasses = generateClasses(transStates)

    return `
            ${initClasses} {
                ${definitions
                    .map((prop) => `${prop[0]}: ${prop[1]};`)
                    .join('\n')}
            }

            ${transClasses} {
                ${shared}
                ${definitions
                    .map((prop) => `${prop[0]}: ${prop[2]};`)
                    .join('\n')}
            }
        `
}

// Helpers for react-transition-group's CSSTransition
export const buildTransitionHelper = {
    enterOrAppear: (classBase: string, transitionDuration: number) =>
        _buildTransitionHelper(
            classBase,
            ['enter', 'appear'],
            ['enter-active', 'appear-active'],
            transitionDuration
        ),
    enter: (classBase: string, transitionDuration: number) =>
        _buildTransitionHelper(
            classBase,
            ['enter'],
            ['enter-active'],
            transitionDuration
        ),
    appear: (classBase: string, transitionDuration: number) =>
        _buildTransitionHelper(
            classBase,
            ['appear'],
            ['appear-active'],
            transitionDuration
        ),
    exit: (classBase: string, transitionDuration: number) =>
        _buildTransitionHelper(
            classBase,
            ['exit'],
            ['exit-active'],
            transitionDuration
        ),
}
