import React from 'react'
import styled from 'styled-components/macro'
import '../assets/loaders.css'
import theme from '../theme'

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({ className = '' }) => {
    return (
        <div className={className}>
            <div className="loader">
                <div className="ball-clip-rotate">
                    <div></div>
                </div>
            </div>
        </div>
    )
}

export default styled(React.memo(Component))`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .ball-clip-rotate {
        transform: scale(2, 2);
    }

    .ball-clip-rotate > div {
        border-color: ${theme.colors.Green};
        border-bottom-color: transparent;
    }
`
