import React, { useEffect } from 'react'
import styled from 'styled-components/macro'
import { useSelector, useDispatch } from 'react-redux'
import { RequestStatus } from '../../types'
import ErrorsDisplay from './ErrorsDisplay'
import { rawDataLoad } from '../../store/raw-data'
import {
    selectRawDataLoadStatus,
    selectRawDataErrors,
    selectRawData,
} from '../../selectors/core'
import Loader from '../Loader'

const Container = styled.div<{ hasErrors: boolean }>`
    height: 100%;
    ${(props) =>
        props.hasErrors
            ? `
        overflow: auto;
    `
            : ``}
`

interface Props {
    fontsLoaded: boolean
}

const LoadingData: React.FunctionComponent<Props> = ({
    children,
    fontsLoaded,
}) => {
    const loadingStatus = useSelector(selectRawDataLoadStatus)
    const gsheetErrors = useSelector(selectRawDataErrors)
    const gsheetData = useSelector(selectRawData)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(rawDataLoad())
    }, [dispatch])

    if (fontsLoaded && loadingStatus === RequestStatus.DONE) {
        if (gsheetData === null) {
            const content = [
                <h1 key="h1">Les données contiennent des erreurs</h1>,
                ...gsheetErrors.map(([label, error]) => (
                    <ErrorsDisplay error={error} label={label} key={label} />
                )),
            ]
            return <Container hasErrors={true}>{content}</Container>
        } else {
            return <>{children}</>
        }
    }

    // CSS loader from : https://github.com/ConnorAtherton/loaders.css
    return (
        <Container hasErrors={false}>
            <Loader />
        </Container>
    )
}

export default LoadingData
