import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import { capitalize } from '../../utils/format'
import DownloadButton from '../DownloadButton'
import Map from '../Map'
import { useOnDownloadClickedCallback } from './hooks'
import MapTitle from './MapTitle'
import { mapContainerMixin, MapDepartementOrRegionOrPaysProps } from './shared'
import { castGroupedResultDepartementOrRegion } from './utils'

const StyledDownloadButton = styled(DownloadButton)`
    margin-top: 1em;
`

const Container = styled.div``

const InnerContainer = styled.div`
    ${mapContainerMixin}
`

const MapDepartementOrRegion: React.FunctionComponent<MapDepartementOrRegionOrPaysProps> = ({
    in: in_,
    mapsData: mapsData_,
    territory,
    filiereFilters,
    className,
}) => {
    const mapsData = castGroupedResultDepartementOrRegion(mapsData_)
    const mapContainerRef = useRef<HTMLDivElement>(null)
    const onDownloadClick = useOnDownloadClickedCallback(
        capitalize(territory.name),
        filiereFilters
    )

    return (
        <Container className={className}>
            <InnerContainer ref={mapContainerRef}>
                <MapTitle hasCloseButton={true} in={in_}>
                    {territory.name}
                </MapTitle>
                <Map
                    in={in_}
                    filiereFilters={filiereFilters}
                    mapData={mapsData.groups.all}
                />
            </InnerContainer>
            <StyledDownloadButton
                onClick={() => onDownloadClick(mapContainerRef)}
            />
        </Container>
    )
}

export default styled(React.memo(MapDepartementOrRegion))`
    /* to position title */
    position: relative;
`
