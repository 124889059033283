import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import { store } from './store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { createGlobalStyle } from 'styled-components'
import theme from './theme'

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    button {
        cursor: pointer;
    }

    a {
        text-decoration: none;
    }

    body {
        overflow: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 16px;
    }

    html,
    body,
    #root {
        width: 100%;
        height: 100%;
        overflow: hidden;
    }

    body {
        color: ${theme.colors.Black};
        font-size: ${theme.fontSizes.baseDesktop}px;
        @media (max-width: ${theme.dimensions.thresholdMobile}px) {
            font-size: ${theme.fontSizes.baseMobile}px;
        }
        font-family: ${theme.fonts.default};
        user-select: none;
    }
`

ReactDOM.render(
    <>
        <GlobalStyle />
        <Provider store={store}>
            <App />
        </Provider>
    </>,
    document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
