import { GeoProjection } from 'd3-geo'
import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { Departement } from '../../config-db'
import { getCanvas2DContext } from '../../core/react'
import theme from '../../theme'
import Canvas from './Canvas'
import { renderDepartements } from './map-canvas'

export const CSS_TRANSITION_CLASSNAMES = 'CanvasDepartements'

export interface Props {
    in: boolean
    width: number
    height: number
    departements: Array<Departement>
    geoProjection: GeoProjection
    onEnterDepartements?: (context: CanvasRenderingContext2D) => void
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const CanvasDepartements: React.FunctionComponent<Props> = ({
    in: in_,
    width,
    height,
    departements,
    geoProjection,
    onEnterDepartements,
    className = '',
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    useEffect(() => {
        const context = getCanvas2DContext(canvasRef)
        if (!context) {
            return
        }
        renderDepartements(context, geoProjection, departements)
        if (onEnterDepartements) {
            onEnterDepartements(context)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <CSSTransition
            in={in_}
            classNames={CSS_TRANSITION_CLASSNAMES}
            nodeRef={canvasRef}
            timeout={{
                enter: theme.transitions.mapsEnterDuration,
                exit: theme.transitions.mapsExitDuration,
            }}
            appear
        >
            <Canvas
                width={width}
                height={height}
                canvasRef={canvasRef}
                className={className}
            />
        </CSSTransition>
    )
}

export default styled(React.memo(CanvasDepartements))`
    width: 100%;
    ${Canvas} {
        width: 100%;
        height: auto;
    }
`
