import React, { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import { buildTransitionHelper } from '../../core/animations'
import { selectWindowDimensions } from '../../selectors/core'
import theme from '../../theme'
import { Position } from '../../types'

const infoBoxEnter = buildTransitionHelper.enterOrAppear(
    '&.InfoBox',
    theme.transitions.infoboxEnterDuration
)
const infoBoxExit = buildTransitionHelper.exit(
    '&.InfoBox',
    theme.transitions.infoboxExitDuration
)

const Container = styled.div`
    /* to avoid triggering mouse out when the mouse is on 
    top of infobox */
    pointer-events: none;
    background-color: ${theme.colors.White};
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: ${theme.dimensions.borderRadius05};
    position: absolute;
    width: fit-content;
    z-index: ${theme.zIndexes.infoBoxes};
    font-size: 88%;
    letter-spacing: 0.05em;
    max-width: 100%;

    ${infoBoxEnter(null, [['opacity', '0', '1']], 0, 1)}

    ${infoBoxExit(null, [['opacity', '1', '0']], 0, 1)}
`

export interface Props {
    position: Position
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const InfoBoxContainer: React.FunctionComponent<PropsWithChildren<Props>> = ({
    position,
    children,
    className = '',
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null)
    const { width: windowWidth } = useSelector(selectWindowDimensions)
    const [adjustedPosition, setAdjustedPosition] = useState(position)
    useEffect(() => {
        const boxRect = containerRef.current!.getBoundingClientRect()
        let adjustedX = position[0]
        let adjustedY = position[1]
        if (boxRect.left + boxRect.width / 2 > windowWidth) {
            adjustedX = windowWidth - boxRect.width
        } else if (boxRect.left - boxRect.width / 2 < 0) {
            adjustedX = 0
        } else {
            adjustedX = boxRect.left - boxRect.width / 2
        }
        setAdjustedPosition([adjustedX, adjustedY])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <Container
            className={className}
            ref={containerRef}
            // Because of some strange interaction between styled components and CSSTransition,
            // we can't use prop to pass the adjusted position
            style={{
                left: adjustedPosition[0],
                top: adjustedPosition[1],
            }}
        >
            {children}
        </Container>
    )
}

export default React.memo(InfoBoxContainer)
