import React from 'react'
import styled from 'styled-components/macro'
import { CanvasDot, MapData } from '../../store/MapsContainer'
import { useMapEventHandlers } from './hooks'
import { Filiere, FiliereFilters } from '../../types'
import { filterCanvasDotsByFilieres } from '../../utils/map'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import CanvasSmallInstallations, {
    CSS_TRANSITION_CLASSNAMES as canvasSmallInstallationsClassnames,
} from './CanvasSmallInstallations'
import CanvasDepartements from './CanvasDepartements'
import CanvasBigInstallations from './CanvasBigInstallations'
import theme from '../../theme'
import HighlightInstallations from './HighlightInstallations'
import { useSelector } from 'react-redux'
import { selectHighlightedCanvasDot } from '../../selectors/MapsContainer'
import { FilieresConfig } from '../../config'
import sortBy from 'lodash.sortby'

const overlayMixin = `
    position: absolute;
    top: 0;
    left: 0;
`

const Container = styled.div``

const CanvasSmallInstallationsOverlay = styled(CanvasSmallInstallations)`
    ${overlayMixin}
`

const CanvasBigInstallationsOverlay = styled(CanvasBigInstallations)`
    ${overlayMixin}
`

const HighlightInstallationsOverlay = styled(HighlightInstallations)`
    ${overlayMixin}
`

export interface Props {
    in: boolean
    mapData: MapData
    filiereFilters: FiliereFilters
    onEnterDepartements?: (context: CanvasRenderingContext2D) => void
    className?: string
    mapDatasEventHandlers?: Array<MapData>
}

const Map: React.FunctionComponent<Props> = ({
    in: in_,
    mapData,
    mapDatasEventHandlers,
    filiereFilters,
    onEnterDepartements,
    className = '',
}) => {
    const {
        geoProjection,
        departements,
        bigCanvasDots,
        smallCanvasDots,
        mapSize,
    } = mapData
    const { eventHandlers } = useMapEventHandlers(
        { width: mapSize, height: mapSize },
        mapDatasEventHandlers || [mapData]
    )
    let highlightedCanvasDot: CanvasDot | null = useSelector(
        selectHighlightedCanvasDot
    )
    if (
        highlightedCanvasDot &&
        !mapData.canvasDots.includes(highlightedCanvasDot)
    ) {
        highlightedCanvasDot = null
    }

    return (
        <Container className={className} {...eventHandlers}>
            <CanvasDepartements
                in={in_}
                width={mapSize}
                height={mapSize}
                departements={departements}
                geoProjection={geoProjection}
                onEnterDepartements={onEnterDepartements}
            />

            {/* We name this transition with same name as CanvasSmallInstallations, so it is caught by the same CSS code
            therefore we can fade out the installations as a whole when changing map */}
            <CSSTransition
                in={in_}
                classNames={canvasSmallInstallationsClassnames}
                timeout={{
                    enter: 0,
                    exit: theme.transitions.mapsExitDuration,
                }}
            >
                <TransitionGroup>
                    {sortBy(
                        Object.entries(
                            filterCanvasDotsByFilieres(
                                smallCanvasDots,
                                filiereFilters
                            )
                        ),
                        ([filiere]) =>
                            FilieresConfig[filiere as Filiere].mapLayerZIndex
                    ).map(([filiere, canvasDots]) => {
                        return (
                            <CanvasSmallInstallationsOverlay
                                key={`small-${filiere}`}
                                width={mapSize}
                                height={mapSize}
                                canvasDots={canvasDots}
                            />
                        )
                    })}
                </TransitionGroup>
            </CSSTransition>

            {/* We make sure to delay the rendering of big installations only after the small installations (quite heavy)
            have been rendered. Otherwise, the UI thread will be blocked and cannot animation frames will jump */}
            <CanvasBigInstallationsOverlay
                in={in_}
                width={mapSize}
                height={mapSize}
                canvasDotsByFiliere={bigCanvasDots}
                filiereFilters={filiereFilters}
            />
            <HighlightInstallationsOverlay
                width={mapSize}
                height={mapSize}
                canvasDot={highlightedCanvasDot}
            />
        </Container>
    )
}

export default styled(React.memo(Map))`
    /*
    touch-action: none;
    pointer-events: none;
    */

    /* To enable absolute positioning of layers of canvas */
    position: relative;
`
