import React, { useState } from 'react'
import styled from 'styled-components/macro'
import Modal from './Modal'
import { ReactComponent as WDDLogoSvg } from '../../images/logo-WDD.svg'

const ORE_EMAIL = 'contact@agenceore.fr'

const AnchorWithMargin = styled.a`
    margin-right: 1em;
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
    onClose: () => void
}

const MentionsLegales: React.FunctionComponent<Props> = ({
    onClose,
    className = '',
}) => {
    const [isEmailShowing, setIsEmailShowing] = useState(false)
    const showEmail = () => setIsEmailShowing(true)
    const email = isEmailShowing ? ORE_EMAIL : 'bla@bla.fr'

    return (
        <Modal title="Mentions légales" className={className} onClose={onClose}>
            <p>
                Cette datavisualisation a été réalisée par l'Agence ORE <br />
                Siège Social : 18 rue de Londres - 75009 Paris <br />
                <AnchorWithMargin
                    href="https://www.agenceore.fr"
                    target="blank_"
                >
                    Site web
                </AnchorWithMargin>
                <a href={`mailto:${email}`} onMouseOver={showEmail}>
                    Contact
                </a>
            </p>

            <p>
                Source des données : <br />
                <a href="https://opendata.reseaux-energies.fr" target="blank_">
                    Open Data Réseaux Énergies (ODRÉ)
                </a>
            </p>

            <p>
                Création datavisualisation : <br />
                <a href="https://www.wedodata.fr/" target="blank_">
                    <WDDLogoSvg />
                </a>
            </p>

            <p>
                Hébergement : <br />
                <a href="https://www.ovh.com/fr/" target="blank_">
                    OVH Cloud
                </a>
            </p>
        </Modal>
    )
}

export default styled(React.memo(MentionsLegales))`
    p {
        text-align: center;
    }
`
