import * as yup from 'yup'
import { DbSpec } from './core/db-core'
import {
    validateRowYup,
    identity,
    inflateRowsWithHeaderAsArray,
    inflateRowsWithHeaderAsObject,
    validateYup,
} from './core/db-operations'
import { fetchJSON, fetchCSV } from './core/requests'
import config, { FilieresConfig } from './config'
import { GeometryCollection } from 'geojson'
import { Filiere, InstallationId, Position } from './types'
import isEqual from 'lodash.isequal'

// let DEPARTEMENTS_URL = 'https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/departements.json'
// let INSTALLATIONS_META_URL = 'https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/installations-meta.json'
// let INSTALLATIONS_URL = 'https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/installations.csv'
// let COMMUNES_URL = 'https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/communes.csv'

let DEPARTEMENTS_URL = `https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/${config.ENV}/departements.json`
let INSTALLATIONS_META_URL = `https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/${config.ENV}/installations-meta.json`
let INSTALLATIONS_URL = `https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/${config.ENV}/installations.csv`
let COMMUNES_URL = `https://cdn-wdd.fra1.digitaloceanspaces.com/projets/agence-ore/dashboard-energies/${config.ENV}/communes.csv`

if (process.env.NODE_ENV === 'development') {
    DEPARTEMENTS_URL = '/production-data/departements.json'
    INSTALLATIONS_META_URL = '/production-data/installations-meta.json'
    INSTALLATIONS_URL = '/production-data/installations.csv'
    COMMUNES_URL = '/production-data/communes.csv'
}

const communeSchema = yup
    .object({
        insee_com: yup.string().required(),
        insee_dep: yup.string().required(),
        insee_reg: yup.string().required(),
        nom_com: yup.string().required(),
        position: yup
            .array()
            .required()
            .of(yup.number().required())
            .min(2)
            .max(2),
    })
    .required()

type CommuneBase = yup.InferType<typeof communeSchema>
type CommuneRaw = CommuneBase & {
    position: string
}
export type Commune = CommuneBase & {
    position: Position
}

const installationsMetaSchema = yup
    .object({
        filiere: yup
            .object()
            .test(
                'has-all-filieres',
                'Filieres in metadata do not correspond with filieres in app',
                (filiere: any) => {
                    const filieresInMeta = new Set(Object.values(filiere))
                    const declaredFilieres = new Set(
                        Object.keys(FilieresConfig)
                    )
                    if (!isEqual(filieresInMeta, declaredFilieres)) {
                        return false
                    }
                    return true
                }
            )
            .required(),
        nominstallation: yup.object().required(),
        dataDate: yup.number().required(),
    })
    .required()
export type InstallationsMeta = yup.InferType<typeof installationsMetaSchema>

const installationSchema = yup
    .object({
        filiere: yup.string().required(),
        commune: communeSchema,
        nbinstallations: yup.number().required(),
        nominstallation: yup.string().required(),
        puissance: yup.number().required(),
        energie: yup.number().required(),
    })
    .required()

type InstallationBase = yup.InferType<typeof installationSchema>
type InstallationRaw = InstallationBase & {
    filiere: string
    nbinstallations: string
    puissance: string
    energie: string
    insee_com: string
}
export type Installation = InstallationBase & {
    id: InstallationId
    filiere: Filiere
    commune: Commune
}

const departementSchema = yup
    .object({
        geo_shape: yup.object().required(),
        insee_dep: yup.string().required(),
        insee_reg: yup.string().required(),
        nom_reg: yup.string().required(),
        nom_dep: yup.string().required(),
    })
    .required()

export type Departement = yup.InferType<typeof departementSchema> & {
    geo_shape: GeometryCollection
}

export const configDb: DbSpec = {
    collections: {
        departements: {
            fetch: fetchJSON.bind(this, DEPARTEMENTS_URL),
            inflate: identity,
            validate: validateRowYup.bind(this, departementSchema),
        },

        communes: {
            fetch: fetchCSV.bind(
                this,
                COMMUNES_URL,
                config.CSV_DELIMITER,
                config.CSV_QUOTE
            ),
            inflate: async (rows) => {
                const communesRaw = await inflateRowsWithHeaderAsObject<
                    CommuneRaw
                >('insee_com', rows)
                // We update the objects without doing copies for better performance
                Object.values(communesRaw).forEach((communeRaw, i) => {
                    const commune = (communeRaw as any) as Commune
                    commune.position = JSON.parse(communeRaw.position)
                })
                return communesRaw
            },
            // Too slow to validate, and we validated also while pre-processing
            validate: identity,
        },

        installationsMeta: {
            fetch: fetchJSON.bind(this, INSTALLATIONS_META_URL),
            inflate: identity,
            validate: validateYup.bind(this, installationsMetaSchema),
        },

        installations: {
            dependencies: ['communes', 'installationsMeta'],
            fetch: fetchCSV.bind(
                this,
                INSTALLATIONS_URL,
                config.CSV_DELIMITER,
                config.CSV_QUOTE
            ),
            inflate: async (rows, dependenciesData) => {
                const installationsMeta: { [key: string]: any } =
                    dependenciesData.installationsMeta
                const installationsRaw = await inflateRowsWithHeaderAsArray<
                    InstallationRaw
                >(rows)
                // We update the objects without doing copies for better performance
                installationsRaw.forEach((installationRaw, i) => {
                    const installation = (installationRaw as any) as Installation
                    installation.id = i
                    installation.nbinstallations = parseInt(
                        installationRaw.nbinstallations,
                        10
                    )
                    installation.puissance = parseFloat(
                        installationRaw.puissance
                    )
                    installation.energie = parseFloat(installationRaw.energie)
                    installation.filiere = installationsMeta.filiere[
                        installationRaw.filiere
                    ] as Filiere
                    installation.nominstallation =
                        (installationsMeta.nominstallation as any)[
                            installationRaw.nominstallation
                        ] || installationRaw.nominstallation
                    installation.commune = (dependenciesData.communes as any)[
                        installationRaw.insee_com
                    ]
                })
                return installationsRaw
            },
            // Too slow to validate, and we validated also while pre-processing
            validate: identity,
        },
    },
}

export default configDb
