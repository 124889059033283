import isEqual from 'lodash.isequal'
import { Installation } from '../config-db'
import { InstallationValueField } from '../types'

export type ValueBounds = [number, number]

export const installationsAreEqual = (
    installations1: Array<Installation>,
    installations2: Array<Installation>
) => {
    const ids1 = new Set(installations1.map((installation) => installation.id))
    const ids2 = new Set(installations2.map((installation) => installation.id))
    return isEqual(ids1, ids2)
}

export const getValueBounds = (
    installations: Array<Installation>,
    installationValueField: InstallationValueField
): ValueBounds => {
    let minValue = Infinity
    let maxValue = -Infinity
    installations.forEach((installation) => {
        minValue = Math.min(installation[installationValueField], minValue)
        maxValue = Math.max(installation[installationValueField], maxValue)
    })
    return [minValue, maxValue]
}
