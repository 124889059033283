import React, { PropsWithChildren } from 'react'
import styled from 'styled-components/macro'
import theme from '../theme'
import infoboxSrc from '../images/icon-infobox.svg'
import { useDispatch } from 'react-redux'
import {
    clearMouseOverSectionInfo,
    setMouseOverSectionInfo,
} from '../store/PageMap'
import { getPageMousePosition } from '../utils/events'

export interface Props {
    infoText?: string
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const SectionTitle: React.FunctionComponent<PropsWithChildren<Props>> = ({
    infoText,
    children,
    className = '',
}) => {
    const dispatch = useDispatch()
    const mouseOverInfo = (event: React.MouseEvent) => {
        if (infoText) {
            dispatch(
                setMouseOverSectionInfo(getPageMousePosition(event), infoText)
            )
        }
    }
    const mouseOut = () => dispatch(clearMouseOverSectionInfo())

    return (
        <h2 className={className}>
            {children}
            {infoText ? (
                <img
                    src={infoboxSrc}
                    onMouseMove={mouseOverInfo}
                    onMouseOut={mouseOut}
                    onTouchEnd={mouseOut}
                    alt="infos"
                />
            ) : null}
        </h2>
    )
}

export default styled(React.memo(SectionTitle))`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    color: ${theme.colors.Black};
    text-transform: uppercase;
    font-weight: normal;
    font-size: 100%;
    letter-spacing: 0.1em;
    margin-bottom: ${theme.spacings.vertical05};

    img {
        display: inline-block;
        margin-left: 0.3em;
        cursor: pointer;
    }

    @media (max-width: 850px) {
        font-size: 90%;
    }
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        font-size: 100%;
    }
`
