import React, { useRef } from 'react'
import styled from 'styled-components/macro'
import {
    MapDepartementOrRegionOrPaysProps,
    OutremerNames,
    OUTREMER_GROUPS,
} from './shared'
import Map from '../Map'
import { mapBorder, mapContainerMixin } from './shared'
import config from '../../config'
import { renderLine } from '../Map/map-canvas'
import { GroupedResultsPays, MapData } from '../../store/MapsContainer'
import MapTitle from './MapTitle'
import { FiliereFilters } from '../../types'
import { castGroupedResultPays } from './utils'
import DownloadButton from '../DownloadButton'
import { useOnDownloadClickedCallback } from './hooks'

const StyledDownloadButton = styled(DownloadButton)`
    margin-top: 1em;
`

const MapCorse = styled(Map)`
    position: absolute;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
`

const MapMetroAndCorseContainer = styled.div`
    position: relative;
    border-bottom: ${mapBorder};
`

const MapOutremersContainer = styled.div`
    display: flex;
    flex-direction: row;
`

const MapOutremerContainer = styled.div`
    /* To position title */
    position: relative;
    border-right: ${mapBorder};
    &:last-child {
        border-right: none;
    }
`

const Container = styled.div``

const InnerContainer = styled.div`
    ${mapContainerMixin}
`

export interface Props {
    in: boolean
    mapsData: GroupedResultsPays<MapData>
    filiereFilters: FiliereFilters
    className?: string
}

const Component: React.FunctionComponent<MapDepartementOrRegionOrPaysProps> = ({
    in: in_,
    mapsData: mapsData_,
    filiereFilters,
    className,
}) => {
    const mapsData = castGroupedResultPays(mapsData_)
    const mapPaysAndCorseContainerRef = useRef<HTMLDivElement>(null)
    const onDownloadClick = useOnDownloadClickedCallback(
        'France métropolitaine',
        filiereFilters
    )

    return (
        <Container className={className}>
            <InnerContainer>
                <MapMetroAndCorseContainer ref={mapPaysAndCorseContainerRef}>
                    <MapTitle in={in_}>France métropolitaine</MapTitle>
                    <Map
                        in={in_}
                        onEnterDepartements={(
                            context: CanvasRenderingContext2D
                        ) => {
                            const [
                                geoPoint1,
                                geoPoint2,
                            ] = config.MAP_CORSE_LINE_FRANCE
                            renderLine(
                                context,
                                mapsData.groups.metro.geoProjection,
                                geoPoint1,
                                geoPoint2
                            )
                        }}
                        mapData={mapsData.groups.metro}
                        filiereFilters={filiereFilters}
                    />
                    <MapCorse
                        in={in_}
                        mapData={mapsData.groups.corse}
                        // The map of Corse overlays the map of metro France, therefore it needs to handle also
                        // events for map data of metro France.
                        mapDatasEventHandlers={[
                            mapsData.groups.metro,
                            mapsData.groups.corse,
                        ]}
                        filiereFilters={filiereFilters}
                    />
                </MapMetroAndCorseContainer>
                <MapOutremersContainer>
                    {OUTREMER_GROUPS.map((group) => (
                        <MapOutremerContainer key={group}>
                            <MapTitle variant="grey" in={in_}>
                                {(OutremerNames as any)[group]}
                            </MapTitle>
                            <Map
                                in={in_}
                                mapData={mapsData.groups[group]}
                                filiereFilters={filiereFilters}
                            />
                        </MapOutremerContainer>
                    ))}
                </MapOutremersContainer>
            </InnerContainer>
            <StyledDownloadButton
                onClick={() => onDownloadClick(mapPaysAndCorseContainerRef)}
            />
        </Container>
    )
}

export default styled(React.memo(Component))``
