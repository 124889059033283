import React, { useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { getCanvas2DContext } from '../../core/react'
import { CanvasDot } from '../../store/MapsContainer'
import theme from '../../theme'
import Canvas from './Canvas'
import { renderInstallations } from './map-canvas'

export const CSS_TRANSITION_CLASSNAMES = 'CanvasSmallInstallations'

export interface Props {
    width: number
    height: number
    canvasDots: Array<CanvasDot>
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const CanvasSmallInstallations: React.FunctionComponent<Props> = ({
    width,
    height,
    canvasDots,
    className = '',
    ...transitionProps
}) => {
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    useEffect(() => {
        const context = getCanvas2DContext(canvasRef)
        if (!context) {
            return
        }
        renderInstallations(context, canvasDots)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <CSSTransition
            classNames={CSS_TRANSITION_CLASSNAMES}
            nodeRef={canvasRef}
            {...transitionProps}
            timeout={{
                enter: theme.transitions.mapsEnterDuration,
                exit: theme.transitions.mapsExitDuration,
            }}
            appear
        >
            <Canvas
                width={width}
                height={height}
                canvasRef={canvasRef}
                className={className}
            />
        </CSSTransition>
    )
}

export default styled(React.memo(CanvasSmallInstallations))`
    width: 100%;
    ${Canvas} {
        width: 100%;
        height: auto;
    }
`
