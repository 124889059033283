import yup from 'yup'

// TODO : FIX THESE USELESS TYPINGS
// Instead have a list of operations whose inputs and outputs must be typechecked
export type DataRaw = any
export type DataInflated =
    | Array<{ [fieldName: string]: any }>
    | { [id: string]: { [fieldName: string]: any } }
export type DataValidated =
    | Array<{ [fieldName: string]: any }>
    | { [id: string]: { [fieldName: string]: any } }

type CollectionId = string
type DependenciesDataValidated = { [collectionId: string]: DataValidated }

export type ResultWithError = [yup.ValidationError | null, any]
export type MultipleResultsWithErrors = {
    [collectionId: string]: ResultWithError
}

export interface CollectionAdapter {
    fetch: (dependencies: DependenciesDataValidated) => Promise<DataRaw>
    inflate: (
        dataRaw: DataRaw,
        dependencies: DependenciesDataValidated
    ) => Promise<DataInflated>
    validate: (
        dataInflated: DataInflated,
        dependencies: DependenciesDataValidated
    ) => Promise<DataValidated>
    dependencies?: Array<CollectionId>
}

interface CollectionAdapterWithId extends CollectionAdapter {
    id: CollectionId
}

export interface DbSpec {
    collections: { [collectionId: string]: CollectionAdapter }
}

const _getCollectionAdapter = (
    dbSpec: DbSpec,
    collectionId: CollectionId
): CollectionAdapterWithId => {
    const collectionAdapter = dbSpec.collections[collectionId]
    if (!collectionAdapter) {
        throw new DependencyMissingError(
            `unknown collection id ${collectionId}`
        )
    }
    return { ...collectionAdapter, id: collectionId }
}

export const loadSingleCollection = async (
    dbSpec: DbSpec,
    collectionId: CollectionId,
    dependenciesData: DependenciesDataValidated
): Promise<DataValidated> => {
    const collectionAdapter = _getCollectionAdapter(dbSpec, collectionId)

    // Check all dependencies are satisfied
    if (collectionAdapter.dependencies) {
        const unsatisfiedDependencies: Array<CollectionId> = []
        collectionAdapter.dependencies.forEach((dependencyCollectionId) => {
            if (!dependenciesData[dependencyCollectionId]) {
                unsatisfiedDependencies.push(dependencyCollectionId)
            }
        })
        if (unsatisfiedDependencies.length) {
            throw new Error(
                `Not all dependencies were satisfied : ${unsatisfiedDependencies.join(
                    ','
                )}`
            )
        }
    }

    // Load the collection
    try {
        const dataRaw = await collectionAdapter.fetch(dependenciesData)
        const dataInflated = await collectionAdapter.inflate(
            dataRaw,
            dependenciesData
        )
        return collectionAdapter.validate(dataInflated, dependenciesData)
    } catch (error) {
        console.error(error)
        throw error
    }
}

export const loadSingleCollectionAndError = async (
    dbSpec: DbSpec,
    collectionId: CollectionId,
    dependenciesData: DependenciesDataValidated
): Promise<ResultWithError> => {
    try {
        const data = await loadSingleCollection(
            dbSpec,
            collectionId,
            dependenciesData
        )
        return [null, data]
    } catch (err) {
        return [err, null]
    }
}

export const _loadSeveralCollectionsAndErrors = async (
    dbSpec: DbSpec,
    collectionIds: Array<CollectionId>,
    results: MultipleResultsWithErrors
): Promise<void> => {
    const resultsList: Array<ResultWithError> = await Promise.all(
        collectionIds.map((collectionId) => {
            const dependenciesData: DependenciesDataValidated = {}
            const collectionAdapter = _getCollectionAdapter(
                dbSpec,
                collectionId
            )
            if (collectionAdapter.dependencies) {
                collectionAdapter.dependencies.forEach(
                    (dependencyCollectionId) => {
                        if (!results[dependencyCollectionId]) {
                            return
                        }
                        dependenciesData[dependencyCollectionId] =
                            results[dependencyCollectionId][1]
                    }
                )
            }
            return loadSingleCollectionAndError(
                dbSpec,
                collectionId,
                dependenciesData
            )
        })
    )
    resultsList.forEach((resultWithError, index) => {
        results[collectionIds[index]] = resultWithError
    })
}

export const loadSeveralCollectionsAndErrors = async (
    dbSpec: DbSpec,
    ...batchedCollectionIds: Array<Array<CollectionId>>
): Promise<MultipleResultsWithErrors> => {
    const results: MultipleResultsWithErrors = {}
    for (let collectionIds of batchedCollectionIds) {
        await _loadSeveralCollectionsAndErrors(dbSpec, collectionIds, results)
    }
    return results
}

class DependencyMissingError extends Error {}
