import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BrowserRouter, Route } from 'react-router-dom'
import { refreshWindowDimensions } from '../store/window-dimensions'
import styled from 'styled-components/macro'
import LoadingData from './LoadingData'
import PageMap from './PageMap'
import config from '../config'

export const Container = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
`

const App = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        const dispatchRefreshWindowDimensions = () =>
            dispatch(refreshWindowDimensions())
        window.addEventListener('resize', dispatchRefreshWindowDimensions)
        return () => {
            window.removeEventListener(
                'resize',
                dispatchRefreshWindowDimensions
            )
        }
    }, [dispatch])

    return (
        <LoadingData fontsLoaded={true}>
            <Container>
                <BrowserRouter basename={config.ROOT_URL}>
                    <Route exact path="/:territoryType?/:territoryName?">
                        <PageMap />
                    </Route>
                </BrowserRouter>
            </Container>
        </LoadingData>
    )
}

export default App
