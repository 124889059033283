import { createSelector } from 'reselect'
import { EnergyMix, Filiere, FiliereFilters } from '../types'
import { selectEnergyMixCurrentTerritory } from './TreeMap'

export const selectEnabledFilieres = createSelector(
    selectEnergyMixCurrentTerritory,
    (energyMix: EnergyMix): FiliereFilters => {
        const filieresEnabled: FiliereFilters = []
        Object.entries(energyMix).forEach(([filiere, value]) => {
            if (value) {
                filieresEnabled.push(filiere as Filiere)
            }
        })
        return filieresEnabled
    }
)
