import React from 'react'
import { components, MenuProps } from 'react-select'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { buildTransitionHelper } from '../../core/animations'
import theme from '../../theme'

const CSS_TRANSITION_CLASSNAMES = 'FooterMobileSelectMenu'

const enterTransition = buildTransitionHelper.enterOrAppear(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.shortDuration
)
const exitTransition = buildTransitionHelper.exit(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.shortDuration
)

const Container = styled.div`
    position: fixed;
    bottom: ${theme.dimensions.footerMobileHeight}px;
    width: 100%;
    left: 0;
    height: calc(
        100% - ${theme.dimensions.footerMobileHeight}px -
            ${theme.dimensions.headerMobileHeight}
    );
    z-index: ${theme.zIndexes.selectMenu};
    border-top: solid 1px #d2d2db;

    .ReactSelect__menu {
        height: 100%;
        /* reset react-select styling */
        position: initial;
        border-radius: 0;
        box-shadow: none;
        padding: 0;
        margin: 0;

        background-color: ${theme.colors.DarkBlue};
        color: ${theme.colors.White};
        text-align: center;

        .ReactSelect__menu-list {
            max-height: initial;
            height: 100%;

            .ReactSelect__option {
                padding: ${theme.spacings.vertical1} 0;
                border-bottom: 1px solid ${theme.colors.DarkBlueDarker};
                cursor: pointer;
                &.ReactSelect__option--is-focused,
                &.ReactSelect__option--is-selected {
                    background-color: transparent;
                }
            }
        }
    }

    ${enterTransition(
        null,
        [['transform', 'translateY(100%)', 'translateY(0%)']],
        0,
        1
    )}
    ${exitTransition(
        null,
        [['transform', 'translateY(0%)', 'translateY(100%)']],
        0,
        1
    )}
`

const Menu: React.FunctionComponent<MenuProps<any>> = (props) => {
    return (
        <CSSTransition
            in={true}
            timeout={theme.transitions.shortDuration}
            classNames={CSS_TRANSITION_CLASSNAMES}
            appear
        >
            <Container>
                <components.Menu {...props} />
            </Container>
        </CSSTransition>
    )
}

export default Menu
