import { geoPath, GeoProjection } from 'd3-geo'
import { Departement } from '../../config-db'
import {
    CanvasDot,
    CanvasDotsIndexedByFiliere,
} from '../../store/MapsContainer'
import theme from '../../theme'

interface AnimationState {
    cancelId: number
}

export const clearCanvas = (context: CanvasRenderingContext2D) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height)
}

export const runAnimation = (
    duration: number,
    callback: (ratio: number) => void
) => {
    let start: number | null = null
    const step = (timestamp: DOMHighResTimeStamp) => {
        if (start === null) {
            start = timestamp
        }
        const elapsed = timestamp - start

        callback(Math.min(elapsed / duration, 1))
        if (elapsed < duration) {
            animationState.cancelId = window.requestAnimationFrame(step)
        }
    }
    const animationState: AnimationState = {
        cancelId: window.requestAnimationFrame(step),
    }
    return animationState
}

export const cancelAnimation = (animationState: AnimationState) =>
    cancelAnimationFrame(animationState.cancelId)

export const renderDepartements = (
    context: CanvasRenderingContext2D,
    geoProjection: GeoProjection,
    departements: Array<Departement>
) => {
    const pathBuilder = geoPath(geoProjection, context)
    context.lineJoin = 'round'
    context.lineCap = 'round'
    context.lineWidth = 1
    context.strokeStyle = theme.maps.colorBackground
    context.fillStyle = theme.maps.colorDepartements

    departements.forEach((departement) => {
        context.beginPath()
        pathBuilder(departement.geo_shape)
        context.fill()
        context.stroke()
    })
}

export const renderInstallations = (
    context: CanvasRenderingContext2D,
    canvasDots: Array<CanvasDot>,
    radiusScale: number = 1
) => {
    canvasDots.forEach(({ position, radius, color }) => {
        context.beginPath()
        context.arc(
            position[0],
            position[1],
            radius * radiusScale,
            0,
            2 * Math.PI
        )
        context.fillStyle = color
        context.fill()
    })
}

const renderInstallationsIndexedByFiliere = (
    context: CanvasRenderingContext2D,
    canvasDotsByFiliere: CanvasDotsIndexedByFiliere,
    radiusScale: number
) => {
    Object.entries(canvasDotsByFiliere).forEach(([_, canvasDots]) => {
        renderInstallations(context, canvasDots, radiusScale)
    })
}

export const renderInstallationsRadiusAnimated = (
    context: CanvasRenderingContext2D,
    canvasDots: {
        in?: CanvasDotsIndexedByFiliere
        out?: CanvasDotsIndexedByFiliere
        stay?: CanvasDotsIndexedByFiliere
    },
    animationDuration: number
) => {
    return runAnimation(animationDuration, (ratio) => {
        clearCanvas(context)
        const ratioOut = 1 - ratio
        if (canvasDots.in) {
            renderInstallationsIndexedByFiliere(context, canvasDots.in, ratio)
        }
        if (canvasDots.out) {
            renderInstallationsIndexedByFiliere(
                context,
                canvasDots.out,
                ratioOut
            )
        }
        if (canvasDots.stay) {
            renderInstallationsIndexedByFiliere(context, canvasDots.stay, 1)
        }
    })
}

export const renderLine = (
    context: CanvasRenderingContext2D,
    geoProjection: GeoProjection,
    geoPoint1: [number, number],
    geoPoint2: [number, number]
) => {
    const point1 = geoProjection(geoPoint1)
    const point2 = geoProjection(geoPoint2)
    if (!point1 || !point2) {
        return
    }
    context.save()
    context.beginPath()
    context.moveTo(point1[0], point1[1])
    context.lineTo(point2[0], point2[1])
    context.strokeStyle = theme.colors.GreyBorder
    context.stroke()
    context.restore()
}
