import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { buildTransitionHelper } from '../../core/animations'
import theme from '../../theme'
import SectionTitle from '../SectionTitle'
import SwitchPuissanceEnergieBase from './SwitchPuissanceEnergieBase'

const CSS_TRANSITION_CLASS_NAME = 'SwitchPuissanceEnergieDesktop'

// Hidden section title so we keep the alignment with the other filters.
const InvisibleSectionTitle = styled(SectionTitle)`
    visibility: hidden;
`

const SwitchContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: ${theme.colors.Grey50};
    border-radius: 0.5em;
    font-size: 90%;
    padding: 1.5em 1em;
`

export interface Props {
    className?: string
}

const SwitchPuissanceEnergieDesktop: React.FunctionComponent<Props> = ({
    className = '',
}) => {
    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                <InvisibleSectionTitle>Unité</InvisibleSectionTitle>
                <SwitchContainer>
                    <SwitchPuissanceEnergieBase />
                </SwitchContainer>
            </div>
        </CSSTransition>
    )
}

const {
    title,
    switchContainer,
} = theme.transitions.pageEnterSequence.SwitchPuissanceEnergieDesktop
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(SwitchPuissanceEnergieDesktop))`
    display: flex;
    flex-direction: column;

    ${appearTransition(
        [`${SectionTitle}`],
        [['opacity', '0', '1']],
        title[0],
        title[1]
    )}

    ${appearTransition(
        [`${SwitchContainer} > *`],
        [['opacity', '0', '1']],
        switchContainer[0],
        switchContainer[1]
    )}
`
