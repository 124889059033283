import { Dimensions, Position } from '../types'

export const getRelativeMousePosition = (event: React.MouseEvent): Position => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect()
    const positionX = event.pageX - currentTargetRect.left
    const positionY = event.pageY - currentTargetRect.top
    return [positionX, positionY]
}

export const getRelativeTouchPosition = (event: React.TouchEvent): Position => {
    const currentTargetRect = event.currentTarget.getBoundingClientRect()
    const positionX = event.touches[0].pageX - currentTargetRect.left
    const positionY = event.touches[0].pageY - currentTargetRect.top
    return [positionX, positionY]
}

export const getPageTouchPosition = (event: React.TouchEvent): Position => {
    return [event.touches[0].pageX, event.touches[0].pageY]
}

export const getPageMousePosition = (event: React.MouseEvent): Position => {
    return [event.pageX, event.pageY]
}

export const getCurrentTargetDimensions = (
    event: React.SyntheticEvent
): Dimensions => {
    const { width, height } = event.currentTarget.getBoundingClientRect()
    return { width, height }
}
