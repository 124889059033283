import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import { FilieresConfig } from '../../config'
import { buildTransitionHelper } from '../../core/animations/helpers'
import theme from '../../theme'
import { Filiere } from '../../types'
import SectionTitle from '../SectionTitle'
import FiliereCapsule from './FiliereCapsule'
import { useFiliereFilters } from './hooks'

const CSS_TRANSITION_CLASS_NAME = 'FiliereFiltersDesktop'

const CapsulesContainer = styled.div``

export interface Props {
    isDataReadyToRender: boolean
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const FiliereFiltersDesktop: React.FunctionComponent<Props> = ({
    isDataReadyToRender,
    className = '',
}) => {
    const { filieres, filieresShowing, filieresEnabled } = useFiliereFilters(
        isDataReadyToRender
    )
    const capsules = filieres.map((filiere) => {
        return (
            <FiliereCapsule
                key={filiere}
                filiere={filiere as Filiere}
                isShowing={filieresShowing.includes(filiere)}
                disabled={!filieresEnabled.includes(filiere)}
            />
        )
    })
    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                <SectionTitle>Filières</SectionTitle>
                <CapsulesContainer>{capsules}</CapsulesContainer>
            </div>
        </CSSTransition>
    )
}

const {
    title,
    capsules,
} = theme.transitions.pageEnterSequence.FiliereFiltersDesktop
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)

export default styled(React.memo(FiliereFiltersDesktop))`
    ${appearTransition(
        [`${SectionTitle}`],
        [['opacity', '0', '1']],
        title[0],
        title[1]
    )}

    /* To avoid capsules from changing height when small */
    ${FiliereCapsule} {
        white-space: nowrap;
    }

    ${() => {
        const capsuleCount = Object.keys(FilieresConfig).length
        const quantum = capsules[1] / Object.keys(FilieresConfig).length
        let css = ''
        for (let i = 1; i < capsuleCount + 1; i++) {
            css += `
                    ${appearTransition(
                        [`${FiliereCapsule}:nth-child(${i}) *`],
                        [['opacity', '0', '1']],
                        capsules[0] + i * quantum,
                        quantum
                    )}
                `
        }
        return css
    }}
`
