import theme from '../../theme'
import {
    GroupedResultsDepartementOrRegion,
    GroupedResultsPays,
    MapData,
} from '../../store/MapsContainer'
import { FiliereFilters, Territory } from '../../types'

export interface MapDepartementOrRegionOrPaysProps {
    in: boolean
    mapsData:
        | GroupedResultsDepartementOrRegion<MapData>
        | GroupedResultsPays<MapData>
    filiereFilters: FiliereFilters
    territory: Territory
    className?: string
}

export const OUTREMER_GROUPS: Array<keyof GroupedResultsPays<any>['groups']> = [
    'guadeloupe',
    'martinique',
    'guyane',
    'laReunion',
]

export const OutremerNames = {
    guadeloupe: 'Guadeloupe',
    martinique: 'Martinique',
    guyane: 'Guyane',
    laReunion: 'La Réunion',
}

export const mapBorder = `1px solid ${theme.colors.GreyBorder}`

export const mapContainerMixin = `
    border: ${mapBorder};
    border-radius: ${theme.dimensions.borderRadius05};
`
