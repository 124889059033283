import React from 'react'
import styled from 'styled-components/macro'
import { InfoBoxTreeMapData } from '../../selectors/InfoBoxes'
import theme from '../../theme'
import FiliereDot from '../FiliereDot'

const Percentage = styled.div`
    font-weight: bold;
    margin-left: 1em;
    font-size: 110%;
`

const FiliereContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${FiliereDot} {
        margin-right: 0.3em;
    }
`

export interface Props {
    infoBoxData: InfoBoxTreeMapData
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({
    infoBoxData,
    className = '',
}) => {
    if (!infoBoxData) {
        return null
    }
    const { filiere, percentageDisplay } = infoBoxData
    return (
        <div className={className}>
            <FiliereContainer>
                <FiliereDot filiere={filiere} disabled={false} />
                <span>{filiere}</span>
            </FiliereContainer>
            <Percentage>{percentageDisplay}</Percentage>
        </div>
    )
}

export default styled(React.memo(Component))`
    padding: 0.2em ${theme.spacings.horizontal05};
`
