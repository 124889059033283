import { createSelector } from 'reselect'
import { FilieresConfig } from '../config'
import { RootState } from '../store'
import { GroupedInstallations } from '../store/MapsContainer'
import {
    EnergyMix,
    Filiere,
    FiliereFilters,
    InstallationValueField,
} from '../types'
import { selectTerritoryInstallations } from './MapsContainer'
import { selectFiliereFilters, selectInstallationValueField } from './PageMap'

export const selectMouseOver = (state: RootState) => state.TreeMap.mouseOver

export const selectEnergyMixCurrentTerritory = createSelector(
    selectTerritoryInstallations,
    selectInstallationValueField,
    (
        installations: GroupedInstallations,
        installationValueField: InstallationValueField
    ) => {
        console.log('SELECTOR : selectEnergyMixCurrentTerritory')
        const energyMix: EnergyMix = {}
        // If ratio stays "null", it means there is no installation for that filiere
        Object.keys(FilieresConfig).forEach((filiere) => {
            energyMix[filiere] = null
        })
        installations.groups.all.forEach((installation) => {
            energyMix[installation.filiere] =
                (energyMix[installation.filiere] || 0) +
                installation[installationValueField]
        })
        return energyMix
    }
)

export const selectEnergyMixRatioFilieresFiltered = createSelector(
    selectEnergyMixCurrentTerritory,
    selectFiliereFilters,
    (energyMix: EnergyMix, filiereFilters: FiliereFilters) => {
        const filteredEnergyMix: EnergyMix = {}
        filiereFilters.forEach((filiere) => {
            filteredEnergyMix[filiere] = energyMix[filiere]
        })

        let valueTotal = 0
        for (let valueFiliere of Object.values(filteredEnergyMix)) {
            valueTotal += valueFiliere || 0
        }

        Object.entries(filteredEnergyMix).forEach(([filiere, valueFiliere]) => {
            filteredEnergyMix[filiere] =
                valueFiliere === null ? null : valueFiliere / valueTotal
        })
        return filteredEnergyMix
    }
)

export const selectEnergyMixFilieresFilteredSubtotal = createSelector(
    selectEnergyMixCurrentTerritory,
    selectFiliereFilters,
    (energyMix: EnergyMix, filiereFilters: FiliereFilters) => {
        let sumTotal = 0
        let sumFiltered = 0
        Object.entries(energyMix).forEach(([filiere, value]) => {
            sumTotal += value || 0
            if (filiereFilters.includes(filiere as Filiere)) {
                sumFiltered += value || 0
            }
        })
        if (sumTotal === 0) {
            return 0
        }
        return sumFiltered / sumTotal
    }
)
