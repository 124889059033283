import React, { useRef } from 'react'
import sortBy from 'lodash.sortby'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'
import {
    selectEnergyMixRatioFilieresFiltered,
    selectEnergyMixFilieresFilteredSubtotal,
} from '../../selectors/TreeMap'
import SectionTitle from '../SectionTitle'
import Graph from './Graph'
import { selectWindowDimensions } from '../../selectors/core'
import {
    selectFiliereFilters,
    selectInstallationValueField,
    selectMapTerritoryFailsafe,
    selectVisualizationDimensions,
} from '../../selectors/PageMap'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import theme from '../../theme'
import { buildTransitionHelper } from '../../core/animations'
import {
    exportPdf,
    generateFilieresLegendHtml,
    generateHtml,
    getSvgStr,
} from '../../utils/export'
import { capitalize, formatNumber } from '../../utils/format'
import DownloadButton from '../DownloadButton'
import { FilieresConfig } from '../../config'

const CSS_TRANSITION_CLASS_NAME = 'TreeMap'
const CSS_TRANSITION_CLASS_NAME_GRAPH = 'TreeMapGraph'

const StyledSectionTitle = styled(SectionTitle)`
    position: relative;
`

const StyledDownloadButton = styled(DownloadButton)``

const DownloadButtonContainer = styled.div`
    margin-top: 1em;
`

const InnerContainer = styled.div<{ noResult: boolean }>`
    ${(props) =>
        props.noResult
            ? `
        position: relative;
        background-color: ${theme.maps.colorDepartements};
    `
            : ``}
    border-radius: ${theme.dimensions.borderRadius05};
`

const NoResultLabel = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin-left: ${theme.spacings.horizontal05};
    margin-top: ${theme.spacings.vertical05};
    color: grey;
`

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const TreeMap: React.FunctionComponent<Props> = ({ className = '' }) => {
    const installationValueField = useSelector(selectInstallationValueField)
    const { width: windowWidth } = useSelector(selectWindowDimensions)
    const filiereFilters = useSelector(selectFiliereFilters)
    const territory = useSelector(selectMapTerritoryFailsafe)
    const energyMixSubtotal = useSelector(
        selectEnergyMixFilieresFilteredSubtotal
    )
    const energyMix = useSelector(selectEnergyMixRatioFilieresFiltered)
    const hasFiliereFiltersApplied =
        Object.keys(FilieresConfig).length !== filiereFilters.length
    const energyMixEntryCount = Object.values(energyMix).filter(
        (value) => value !== null
    ).length
    const {
        width: visualizationWidth,
        treeMapHeight: visualizationHeight,
    } = useSelector(selectVisualizationDimensions)
    const containerRef = useRef<HTMLDivElement>(null)
    const onDownloadClick = () => {
        const svgStr = getSvgStr(containerRef.current!)
        const htmlStr = generateHtml(
            `Composition du parc installé${
                hasFiliereFiltersApplied ? ' (sélection)' : ''
            }`,
            capitalize(territory.name),
            `<div class="treeMap">
                ${svgStr}
                <div class="legend">
                    ${generateFilieresLegendHtml(
                        sortBy(
                            filiereFilters,
                            (filiere) => -(energyMix[filiere] || 0)
                        ).map((filiere) => {
                            const valuePercent = (energyMix[filiere] || 0) * 100
                            const value =
                                valuePercent > 0.1
                                    ? `${formatNumber(valuePercent, 1)}%`
                                    : '<0,1%'
                            return { filiere, value }
                        }),
                        hasFiliereFiltersApplied
                            ? energyMixSubtotal * 100
                            : undefined
                    )}
                </div>
            </div>`,
            'treeMapViz'
        )
        exportPdf('graphique.pdf', htmlStr)
    }

    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
        >
            <div className={className}>
                <StyledSectionTitle
                    infoText={
                        installationValueField === 'puissance'
                            ? 'Capacité installée de production'
                            : 'Origine de l’énergie produite sur l’année écoulée'
                    }
                >
                    {installationValueField === 'puissance'
                        ? 'Composition du parc installé'
                        : 'Origine de l’énergie produite'}
                </StyledSectionTitle>
                <InnerContainer
                    noResult={energyMixEntryCount === 0}
                    ref={containerRef}
                >
                    {energyMixEntryCount === 0 ? (
                        <NoResultLabel>
                            Pas d'énergie présente sélectionnée.
                        </NoResultLabel>
                    ) : null}
                    <SwitchTransition>
                        <CSSTransition
                            timeout={{
                                enter: theme.transitions.enterDuration,
                                exit: theme.transitions.exitDuration,
                            }}
                            unmountOnExit={true}
                            classNames={CSS_TRANSITION_CLASS_NAME_GRAPH}
                            // Build the key with both the width and territoryName to force complete re-render
                            // when size changes
                            key={`${territory.name}-${windowWidth}-${installationValueField}`}
                        >
                            <Graph
                                energyMix={energyMix}
                                width={visualizationWidth}
                                height={visualizationHeight}
                            />
                        </CSSTransition>
                    </SwitchTransition>
                </InnerContainer>
                <DownloadButtonContainer>
                    <StyledDownloadButton onClick={onDownloadClick} />
                </DownloadButtonContainer>
            </div>
        </CSSTransition>
    )
}

const { title, visualization } = theme.transitions.pageEnterSequence.TreeMap
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)
const graphEnterTransition = buildTransitionHelper.enterOrAppear(
    `.${CSS_TRANSITION_CLASS_NAME_GRAPH}`,
    theme.transitions.mapsEnterDuration
)
const graphExitTransition = buildTransitionHelper.exit(
    `.${CSS_TRANSITION_CLASS_NAME_GRAPH}`,
    theme.transitions.mapsExitDuration
)

export default styled(React.memo(TreeMap))`
    ${appearTransition(
        [`${SectionTitle}`],
        [['opacity', '0', '1']],
        title[0],
        title[1]
    )}

    ${appearTransition(
        [`${InnerContainer}`],
        [['opacity', '0', '1']],
        visualization[0],
        visualization[1]
    )}

    ${graphEnterTransition(null, [['opacity', '0', '1']], 0, 1)}
    ${graphExitTransition(null, [['opacity', '1', '0']], 0, 1)}


    display: flex;
    flex-direction: column;
`
