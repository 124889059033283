import React from 'react'
import styled from 'styled-components/macro'
import Switch from 'react-switch'
import { useDispatch, useSelector } from 'react-redux'
import { setInstallationValueField } from '../../store/PageMap'
import { selectInstallationValueField } from '../../selectors/PageMap'
import { InstallationValueField } from '../../types'
import theme from '../../theme'

const checkedToInstallationValueField = (
    checked: boolean
): InstallationValueField => (checked ? 'energie' : 'puissance')
const installationValueFieldToChecked = (
    installationValueField: InstallationValueField
): boolean => (installationValueField === 'energie' ? true : false)

const Label = styled.div<{ disabled: boolean }>`
    ${(props) =>
        props.disabled ? `color: ${theme.colors.DisabledSwitchLabel};` : ''};
`

const StyledSwitch = styled(Switch)`
    margin: 0 0.5em;
    .react-switch-bg {
        border: 1px solid;
    }
`

export interface Props {
    className?: string
}

const SwitchPuissanceEnergieBase: React.FunctionComponent<Props> = ({
    className = '',
}) => {
    const dispatch = useDispatch()
    const installationValueField = useSelector(selectInstallationValueField)
    const onChange = (checked: boolean) =>
        dispatch(
            setInstallationValueField(checkedToInstallationValueField(checked))
        )
    return (
        <div className={className}>
            <Label disabled={installationValueField !== 'puissance'}>
                Puissance installée
            </Label>
            <StyledSwitch
                onChange={onChange}
                checked={installationValueFieldToChecked(
                    installationValueField
                )}
                height={20}
                width={36}
                handleDiameter={14}
                uncheckedIcon={false}
                checkedIcon={false}
                offColor={theme.colors.White}
                onColor={theme.colors.White}
                offHandleColor={theme.colors.Black}
                onHandleColor={theme.colors.Black}
            />
            <Label disabled={installationValueField !== 'energie'}>
                Énergie produite
            </Label>
        </div>
    )
}

export default styled(React.memo(SwitchPuissanceEnergieBase))`
    display: flex;
    flex-direction: row;
    justify-content: center;
`
