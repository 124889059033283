import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as DlSvg } from '../images/bt-download.svg'
import theme from '../theme'

export interface Props {
    onClick: (event: React.MouseEvent) => void
    className?: string
}

const DownloadButton: React.FunctionComponent<Props> = ({
    onClick,
    className = '',
}) => {
    return (
        <button className={className} onClick={onClick}>
            <span>Télécharger</span>
            <DlSvg />
        </button>
    )
}

export default styled(React.memo(DownloadButton))`
    font-family: ${theme.fonts.default};
    font-size: 85%;
    color: ${theme.colors.DarkBlue};
    background-color: #e4e4e9;
    opacity: 0.8;
    padding: 0.35em 0.8em;
    border: none;
    border-radius: 1em;
    display: flex;
    flex-direction: revert;
    align-items: center;
    font-weight: bold;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        display: none;
    }

    &:hover {
        background-color: #bfece7;
        opacity: 1;
    }

    svg {
        width: 1.2em;
        margin-left: 0.8em;
    }
`
