import React, { PropsWithChildren, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components/macro'
import theme from '../../theme'
import { ReactComponent as CloseSvg } from '../../images/popin-close.svg'
import { buildTransitionHelper } from '../../core/animations'

const CSS_TRANSITION_CLASSNAMES = 'Modal'

const Overlay = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: ${theme.zIndexes.modalOverlay};
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        background-color: rgba(0, 0, 0, 0);
    }
`

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: ${theme.colors.White};
    overflow: auto;
    width: 70%;
    max-width: 1090px;
    max-height: 70%;
    font-size: 130%;
    border-radius: ${theme.dimensions.borderRadius05};
    padding-bottom: ${theme.spacings.vertical2};
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        width: 100%;
        height: calc(
            100% - ${theme.dimensions.headerMobileHeight} -
                ${theme.dimensions.footerMobileHeight}px
        );
        top: ${theme.dimensions.headerMobileHeight};
        position: absolute;
        border-radius: 0;
    }

    h3 {
        margin-bottom: ${theme.spacings.vertical05};
    }

    a {
        color: ${theme.colors.Green};
        text-decoration: underline;
    }

    p {
        margin-bottom: ${theme.spacings.vertical2};
    }
`

const ModalInnerContainer = styled.div`
    /* allow scrolling only on the text part */
    flex: 1;
    overflow: auto;
    padding: 0 10%;
    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        padding: 0 ${theme.spacings.horizontal1};
    }
`

const CloseButton = styled.button`
    border: none;
    background-color: ${theme.colors.GreyLight};
    border-radius: 2em;
    height: 3em;
    width: 3em;
    &:hover {
        background-color: ${theme.colors.Green};
        svg * {
            fill: ${theme.colors.White};
            stroke: ${theme.colors.White};
        }
    }

    svg {
        display: inline-block;
        vertical-align: middle;
    }
`

const ModalHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: ${theme.spacings.horizontal05};
`

const ModalTitle = styled.h2`
    text-align: center;
    margin-bottom: ${theme.spacings.vertical2};
`

export interface Props {
    title: string
    onClose: () => void
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Modal: React.FunctionComponent<PropsWithChildren<Props>> = ({
    title,
    onClose,
    children,
    className = '',
}) => {
    const [isIn, setIsIn] = useState(true)
    const startCloseTransition = () => setIsIn(false)
    const stopClick = (event: React.MouseEvent) => event.stopPropagation()
    return (
        <CSSTransition
            in={isIn}
            timeout={theme.transitions.modalDuration}
            classNames={CSS_TRANSITION_CLASSNAMES}
            onExited={onClose}
            appear
        >
            <div className={className}>
                <Overlay onClick={startCloseTransition}>
                    <ModalContainer onClick={stopClick}>
                        <ModalHeader>
                            <CloseButton onClick={startCloseTransition}>
                                <CloseSvg />
                            </CloseButton>
                        </ModalHeader>
                        <ModalTitle>{title}</ModalTitle>
                        <ModalInnerContainer>{children}</ModalInnerContainer>
                    </ModalContainer>
                </Overlay>
            </div>
        </CSSTransition>
    )
}

const enterTransition = buildTransitionHelper.enterOrAppear(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.modalDuration
)
const exitTransition = buildTransitionHelper.exit(
    `&.${CSS_TRANSITION_CLASSNAMES}`,
    theme.transitions.modalDuration
)
export default styled(React.memo(Modal))`
    ${enterTransition([`${Overlay}`], [['opacity', '0', '1']], 0, 1)}

    ${enterTransition(
        [`${ModalContainer}`],
        [
            ['opacity', '0', '1'],
            ['transform', 'translateY(100%)', 'translateY(0%)'],
        ],
        0,
        1
    )}

    ${exitTransition([`${Overlay}`], [['opacity', '1', '0']], 0, 1)}

    ${exitTransition(
        [`${ModalContainer}`],
        [
            ['opacity', '1', '0'],
            ['transform', 'translateY(0%)', 'translateY(100%)'],
        ],
        0,
        1
    )}
`
