import React from 'react'
import styled from 'styled-components/macro'
import * as yup from 'yup'

const Container = styled.div``

const Msg = styled.li``

const Title = styled.h2``

interface Props {
    label: string
    error: Error
}

const ErrorsDisplay: React.FunctionComponent<Props> = ({ label, error }) => {
    let errorMsgs: Array<string>
    if (error instanceof yup.ValidationError) {
        errorMsgs = error.errors
    } else {
        errorMsgs = [error.toString()]
    }

    return (
        <Container>
            <Title>{label}</Title>
            <ul>
                {errorMsgs.map((errorMsg) => (
                    <Msg key={errorMsg}>{errorMsg}</Msg>
                ))}
            </ul>
        </Container>
    )
}

export default ErrorsDisplay
