// ---------- Types definitions ---------- //

import { Filiere, Position } from '../types'

// ------------- Action Types ------------ //
export const TREE_MAP_MOUSE_OVER_RECT = 'TREE_MAP_MOUSE_OVER_RECT'

interface MouseOverRect {
    type: typeof TREE_MAP_MOUSE_OVER_RECT
    payload: MouseOverState
}

export type TreeMapActionsType = MouseOverRect

// ------------ Action Creators ---------- //
export const mouseOverRect = (
    pageMousePosition: Position,
    filiere: Filiere,
    percentageDisplay: string,
    textHidden: boolean
): TreeMapActionsType => {
    return {
        type: TREE_MAP_MOUSE_OVER_RECT,
        payload: {
            pageMousePosition,
            filiere,
            percentageDisplay,
            textHidden,
        },
    }
}

export const clearMouseOverRect = (): TreeMapActionsType => {
    return {
        type: TREE_MAP_MOUSE_OVER_RECT,
        payload: null,
    }
}

// ----------------- State --------------- //
export type MouseOverState = {
    pageMousePosition: Position
    filiere: Filiere
    percentageDisplay: string
    textHidden: boolean
} | null

export interface TreeMapState {
    mouseOver: MouseOverState
}

const initialState: TreeMapState = {
    mouseOver: null,
}

// ---------------- Reducer -------------- //
export function treeMapReducer(
    state = initialState,
    action: TreeMapActionsType
): TreeMapState {
    switch (action.type) {
        case TREE_MAP_MOUSE_OVER_RECT:
            // Avoid to change state if already the same filiere, so we don't show/hide the same infobox
            if (
                state.mouseOver &&
                action.payload &&
                state.mouseOver.filiere === action.payload.filiere
            ) {
                return state
            }
            return {
                ...state,
                mouseOver: action.payload,
            }
        default:
            return state
    }
}
