import { scaleLinear } from 'd3-scale'
import { useSelector } from 'react-redux'
import {
    selectMapValueToRadius,
    selectInstallationsValueBounds,
} from '../../selectors/MapsContainer'
import { selectInstallationValueField } from '../../selectors/PageMap'
import { FiliereFilters } from '../../types'
import {
    mergeCanvases,
    generateHtml,
    generateFilieresLegendHtml,
    exportPdf,
} from '../../utils/export'
import { capitalize, formatValue } from '../../utils/format'
import { ValueBounds } from '../../utils/installations'

const LEGEND_SIZE_CIRCLE_COUNT = 5

const sampleValues = (valueBounds: ValueBounds) => {
    const expFunc = (x: number) => Math.pow(2, x)
    const scaleToValueBounds = scaleLinear()
        .domain([expFunc(0), expFunc(LEGEND_SIZE_CIRCLE_COUNT - 1)])
        .range(valueBounds)

    const valuesSample: Array<number> = []
    for (let i = 0; i < LEGEND_SIZE_CIRCLE_COUNT; i++) {
        valuesSample.push(scaleToValueBounds(expFunc(i)))
    }
    return valuesSample
}

export const useOnDownloadClickedCallback = (
    territoryName: string,
    filiereFilters: FiliereFilters
) => {
    const installationValueField = useSelector(selectInstallationValueField)
    const mapValueToRadius = useSelector(selectMapValueToRadius)
    const valueBounds = useSelector(selectInstallationsValueBounds)
    const valuesSample = sampleValues(valueBounds)
    const maxRadius = mapValueToRadius(valuesSample.slice(-1)[0])

    return (containerRef: React.MutableRefObject<any>) => {
        mergeCanvases(containerRef.current!).then((imgSrc) => {
            const unit = installationValueField === 'puissance' ? 'W' : 'Wh'
            const htmlStr = generateHtml(
                installationValueField === 'puissance'
                    ? 'Localisation et puissance des installations'
                    : 'Localisation et énergie produite par les installations',
                capitalize(territoryName),
                `<div class="map">
                    <img src="${imgSrc}" />
                    <div class="legend">
                        <div class="legendSizeInstallations">
                            ${valuesSample
                                .map((value) => {
                                    const radius = mapValueToRadius(value)
                                    return `
                                    <div class="legendSizeInstallationsItem">
                                        <div style="width:${maxRadius}px;">
                                            <div style="width:${radius}px;height:${radius}px;border-radius:${radius}px"></div>
                                        </div>
                                        <div>${formatValue(
                                            value,
                                            unit,
                                            1
                                        )}</div>
                                    </div>
                                `
                                })
                                .join('')}
                        </div>
                        ${generateFilieresLegendHtml(
                            filiereFilters.map((filiere) => ({ filiere }))
                        )}
                    </div>
                </div>`
            )
            exportPdf('graphique.pdf', htmlStr)
        })
    }
}
