import { Filiere, EnergyMix } from '../types'

export interface D3HierarchyData {
    filiere: Filiere
    ratio: number
}

export const energyMixToD3Hierarchy = (energyMix: EnergyMix) => {
    return {
        children: Object.entries(energyMix)
            // ratio === null means there is no installation for that filiere
            .filter(([filiere, ratio]) => ratio !== null)
            .map(([filiere, ratio]) => ({
                filiere: filiere as Filiere,
                ratio,
            })),
    }
}
