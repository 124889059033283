import React from 'react'
import styled from 'styled-components/macro'
import { ReactComponent as IconLocalisation } from '../../images/icon-localisation.svg'
import InfoBoxMapEntry from './InfoBoxMapEntry'
import theme from '../../theme'
import { InfoBoxMapData } from '../../selectors/InfoBoxes'
import { useSelector } from 'react-redux'
import { selectInstallationValueField } from '../../selectors/PageMap'

const sectionMixin = `
    padding: ${theme.spacings.vertical05} ${theme.spacings.horizontal05};
`

const Commune = styled.div`
    ${sectionMixin}
    border-bottom: 1px solid ${theme.colors.Grey};

    svg {
        display: inline-block;
        margin-right: 0.3em;
    }
`
const CommuneLabel = styled.span``

const InfoMapBoxEntries = styled.div`
    ${sectionMixin}
`

const FiliereSeparatorLine = styled.div`
    display: block;
    width: 50%;
    border-bottom: solid 1px ${theme.colors.Grey};
    transform: translateX(50%);
    margin: 0.3em 0;
`

export interface Props {
    infoBoxData: InfoBoxMapData
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const InfoBoxMap: React.FunctionComponent<Props> = ({
    infoBoxData,
    className = '',
}) => {
    const installationValueField = useSelector(selectInstallationValueField)
    if (!infoBoxData || infoBoxData.installations.length === 0) {
        return null
    }
    const { commune, installations } = infoBoxData
    installations.sort(
        (installation1, installation2) =>
            installation2[installationValueField] -
            installation1[installationValueField]
    )

    return (
        <div className={className}>
            <Commune>
                <IconLocalisation />
                <CommuneLabel>
                    {commune.nom_com} ({commune.insee_dep})
                </CommuneLabel>
            </Commune>
            <InfoMapBoxEntries>
                {installations.map((installation, i) => (
                    <React.Fragment key={installation.filiere}>
                        <InfoBoxMapEntry installation={installation} />
                        {i < installations.length - 1 ? (
                            <FiliereSeparatorLine />
                        ) : null}
                    </React.Fragment>
                ))}
            </InfoMapBoxEntries>
        </div>
    )
}

export default styled(React.memo(InfoBoxMap))`
    text-align: center;
    min-width: 13em;
    max-width: 260px;
`
