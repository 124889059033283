import React, { PropsWithChildren, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    CSSTransition,
    SwitchTransition,
    Transition,
} from 'react-transition-group'
import styled from 'styled-components/macro'
import { buildTransitionHelper } from '../../core/animations'
import DelayMount from '../../core/animations/DelayMount'
import { selectWindowDimensions } from '../../selectors/core'
import { selectMapsData } from '../../selectors/MapsContainer'
import {
    selectFiliereFilters,
    selectInstallationValueField,
    selectMapTerritoryFailsafe,
} from '../../selectors/PageMap'
import { selectVisualizationDimensions } from '../../selectors/PageMap'
import { GroupedType, MapsData } from '../../store/MapsContainer'
import theme from '../../theme'
import { FiliereFilters, Territory } from '../../types'
import { CSS_TRANSITION_CLASSNAMES as canvasDepartementsClassnames } from '../Map/CanvasDepartements'
import { CSS_TRANSITION_CLASSNAMES as canvasSmallInstallationsClassnames } from '../Map/CanvasSmallInstallations'
import SectionTitle from '../SectionTitle'
import MapDepartementOrRegion from './MapDepartementOrRegion'
import MapPays from './MapPays'
import { CSS_TRANSITION_CLASSNAMES as mapTitleClassnames } from './MapTitle'

const CSS_TRANSITION_CLASS_NAME = 'MapsContainer'

// This is a trick to force a vertical size of `Sized` to the size of `Sizer`
const SizerContainer = styled.div`
    position: relative;
`

const Sizer = styled.canvas`
    width: 100%;
    height: auto;
`

const Sized = styled.div`
    position: absolute;
    top: 0;
    left: 0;
`

const InnerContainer = styled.div``

interface WrappedMapProps {
    Component: typeof MapDepartementOrRegion | typeof MapPays
    in: boolean
    isFirstRender: boolean
    visualizationEnterStartTime: number
    territory: Territory
    filiereFilters: FiliereFilters
    mapsData: MapsData
}

const WrappedMap: React.FunctionComponent<PropsWithChildren<
    WrappedMapProps
>> = ({
    Component,
    in: in_,
    isFirstRender,
    visualizationEnterStartTime,
    territory,
    filiereFilters,
    mapsData,
}) => (
    <DelayMount
        in={in_}
        delay={isFirstRender ? visualizationEnterStartTime : 0}
    >
        <CSSTransition
            in={in_}
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
            classNames="InnerContainer"
            appear
        >
            <Sized>
                <Component
                    in={in_}
                    territory={territory}
                    filiereFilters={filiereFilters}
                    mapsData={mapsData}
                />
            </Sized>
        </CSSTransition>
    </DelayMount>
)

export interface Props {
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const MapsContainer: React.FunctionComponent<Props> = ({ className = '' }) => {
    const installationValueField = useSelector(selectInstallationValueField)
    const [isFirstRender, setIsFirstRender] = useState(true)
    const territory = useSelector(selectMapTerritoryFailsafe)
    const { width: windowWidth } = useSelector(selectWindowDimensions)
    const mapsData = useSelector(selectMapsData)
    const filiereFilters = useSelector(selectFiliereFilters)

    let visualizationEnterStartRatio =
        theme.transitions.pageEnterSequence.MapsContainer.visualization[0]
    if (windowWidth < theme.dimensions.thresholdMobile) {
        visualizationEnterStartRatio =
            theme.transitions.pageEnterSequenceMobile.MapsContainer
                .visualization[0]
    }
    const visualizationEnterStartTime =
        visualizationEnterStartRatio * theme.transitions.enterDuration

    const {
        width: visualizationWidth,
        mapHeight: visualizationHeight,
    } = useSelector(selectVisualizationDimensions)
    return (
        <CSSTransition
            in={true}
            classNames={CSS_TRANSITION_CLASS_NAME}
            appear
            timeout={{
                enter: theme.transitions.enterDuration,
                exit: theme.transitions.exitDuration,
            }}
            onEntered={() => setIsFirstRender(false)}
        >
            <div className={className}>
                <SectionTitle infoText="Les sites de production sont localisés par leur commune d'implantation">
                    {installationValueField === 'puissance'
                        ? 'Localisation et puissance des installations'
                        : 'Localisation et énergie produite par les installations'}
                </SectionTitle>
                <SizerContainer>
                    <Sizer
                        width={visualizationWidth}
                        height={visualizationHeight}
                    />
                    <SwitchTransition>
                        <Transition
                            timeout={{
                                enter: theme.transitions.enterDuration,
                                exit: theme.transitions.exitDuration,
                            }}
                            unmountOnExit={true}
                            // Build the key with both the width and territoryName to force complete re-render
                            // when size changes
                            key={`${territory.name}-${windowWidth}-${installationValueField}`}
                        >
                            {(transitionStatus) => {
                                const in_ = ['entering', 'entered'].includes(
                                    transitionStatus
                                )
                                switch (mapsData.type) {
                                    case GroupedType.DEPARTEMENT_OR_REGION:
                                        return (
                                            <WrappedMap
                                                in={in_}
                                                isFirstRender={isFirstRender}
                                                visualizationEnterStartTime={
                                                    visualizationEnterStartTime
                                                }
                                                territory={territory}
                                                filiereFilters={filiereFilters}
                                                mapsData={mapsData}
                                                Component={
                                                    MapDepartementOrRegion
                                                }
                                            />
                                        )
                                    case GroupedType.PAYS:
                                        return (
                                            <WrappedMap
                                                in={in_}
                                                isFirstRender={isFirstRender}
                                                visualizationEnterStartTime={
                                                    visualizationEnterStartTime
                                                }
                                                territory={territory}
                                                filiereFilters={filiereFilters}
                                                mapsData={mapsData}
                                                Component={MapPays}
                                            />
                                        )
                                }
                            }}
                        </Transition>
                    </SwitchTransition>
                </SizerContainer>
            </div>
        </CSSTransition>
    )
}

const {
    title,
    visualization,
} = theme.transitions.pageEnterSequence.MapsContainer
const {
    title: titleMobile,
    visualization: visualizationMobile,
} = theme.transitions.pageEnterSequenceMobile.MapsContainer
const appearTransition = buildTransitionHelper.appear(
    `&.${CSS_TRANSITION_CLASS_NAME}`,
    theme.transitions.enterDuration
)

const exitInnerContainer = buildTransitionHelper.exit(
    `.InnerContainer`,
    theme.transitions.exitDuration
)
const enterInnerContainer = buildTransitionHelper.enter(
    `.InnerContainer`,
    theme.transitions.enterDuration
)

const mapTitleEnter = buildTransitionHelper.enterOrAppear(
    `.${mapTitleClassnames}`,
    theme.transitions.mapsEnterDuration
)
const mapTitleExit = buildTransitionHelper.exit(
    `.${mapTitleClassnames}`,
    theme.transitions.mapsExitDuration
)

const enterDepartements = buildTransitionHelper.enterOrAppear(
    `.${canvasDepartementsClassnames}`,
    theme.transitions.mapsEnterDuration
)

const exitDepartements = buildTransitionHelper.exit(
    `.${canvasDepartementsClassnames}`,
    theme.transitions.mapsExitDuration
)

const enterSmallInstallations = buildTransitionHelper.enterOrAppear(
    `.${canvasSmallInstallationsClassnames}`,
    theme.transitions.mapsEnterDuration
)

const exitSmallInstallations = buildTransitionHelper.exit(
    `.${canvasSmallInstallationsClassnames}`,
    theme.transitions.mapsExitDuration
)

export default styled(React.memo(MapsContainer))`
    ${appearTransition(
        [`${SectionTitle}`],
        [['opacity', '0', '1']],
        title[0],
        title[1]
    )}

    ${appearTransition(
        [`${InnerContainer}`],
        [['opacity', '0', '1']],
        visualization[0],
        visualization[1]
    )}

    /* These two transitions are important to hide the border between 2 map renders */
    ${enterInnerContainer(null, [['opacity', '0', '1']], 0, 0.1)}
    ${exitInnerContainer(null, [['opacity', '1', '0']], 0.5, 0.5)}

    @media (max-width: ${theme.dimensions.thresholdMobile}px) {
        ${appearTransition(
            [`${SectionTitle}`],
            [['opacity', '0', '1']],
            titleMobile[0],
            titleMobile[1]
        )}

        ${appearTransition(
            [`${InnerContainer}`],
            [['opacity', '0', '1']],
            visualizationMobile[0],
            visualizationMobile[1]
        )}
    }

    ${enterDepartements(null, [['opacity', '0', '1']], 0, 0.5)}
    ${exitDepartements(null, [['opacity', '1', '0']], 0, 1)}

    ${enterSmallInstallations(null, [['opacity', '0', '1']], 0.3, 0.8)}
    ${exitSmallInstallations(null, [['opacity', '1', '0']], 0, 1)}

    ${mapTitleEnter(null, [['opacity', '0', '1']], 0, 0.5)}
    ${mapTitleExit(null, [['opacity', '1', '0']], 0, 0.5)}
`
