import React from 'react'
import styled from 'styled-components/macro'
import SwitchPuissanceEnergieBase from './SwitchPuissanceEnergieBase'

export interface Props {
    className?: string
}

const SwitchPuissanceEnergieMobile: React.FunctionComponent<Props> = ({
    className = '',
}) => {
    return (
        <div className={className}>
            <SwitchPuissanceEnergieBase />
        </div>
    )
}

export default styled(React.memo(SwitchPuissanceEnergieMobile))`
    padding: 1.8em 0;
`
