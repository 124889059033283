import React, { useState, useEffect, PropsWithChildren } from 'react'

interface Props {
    in: boolean
    delay: number
    children: JSX.Element
}

const DelayMount: React.FunctionComponent<PropsWithChildren<Props>> = ({
    in: in_,
    delay,
    children,
}) => {
    const [shouldBeMounted, setShouldBeMounted] = useState<boolean>(
        in_ === true && delay === 0
    )
    useEffect(() => {
        if (in_ === true && shouldBeMounted === false) {
            const timeout = setTimeout(() => {
                setShouldBeMounted(true)
            }, delay)
            return () => clearTimeout(timeout)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [in_, delay])

    if (shouldBeMounted) {
        return children
    }
    return null
}

export default DelayMount
