import React from 'react'
import styled from 'styled-components/macro'
import { InfoBoxSectionInfoData } from '../../selectors/InfoBoxes'
import theme from '../../theme'

export interface Props {
    infoBoxData: InfoBoxSectionInfoData
    className?: string
}

const InfoBoxSectionInfo: React.FunctionComponent<Props> = ({
    infoBoxData,
    className = '',
}) => {
    return <div className={className}>{infoBoxData.text}</div>
}

export default styled(React.memo(InfoBoxSectionInfo))`
    max-width: 20em;
    padding: ${theme.spacings.vertical1} ${theme.spacings.horizontal1};
`
