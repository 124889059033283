import { MapData } from '../store/MapsContainer'
import Flatbush from 'flatbush'

export const buildFlatbush = (mapData: MapData) => {
    const flatbush = new Flatbush(mapData.canvasDots.length)
    for (const canvasDot of mapData.canvasDots) {
        const [x, y] = canvasDot.position
        const radius = canvasDot.radius
        flatbush.add(x - radius, y - radius, x + radius, y + radius)
    }
    flatbush.finish()
    return flatbush
}

export const flatbushIndexToCanvasDot = (
    flatbushIndex: number,
    mapData: MapData
) => mapData.canvasDots[flatbushIndex]

export const flatbushIndexToInstallationId = (
    flatbushIndex: number,
    mapData: MapData
) => mapData.canvasDots[flatbushIndex].installationId
