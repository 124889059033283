import React from 'react'
import styled from 'styled-components/macro'
import { FilieresConfig } from '../config'
import theme from '../theme'
import { Filiere } from '../types'

export interface Props {
    filiere: Filiere
    disabled: boolean
    // className is important if we want to allow styled-components
    // to work with this component.
    className?: string
}

const Component: React.FunctionComponent<Props> = ({ className = '' }) => {
    return <div className={className}></div>
}

export default styled(React.memo(Component))`
    display: inline-block;
    height: 1em;
    width: 1em;
    border-radius: 50%;
    ${(props) =>
        props.disabled
            ? `
        background-color: ${theme.colors.Grey150};
    `
            : `
        background-color: ${FilieresConfig[props.filiere].color};
    `}
`
